import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import MDButton from "components/MDButton";

const containerStyle = {
    width: '400px',
    height: '400px'
  };

function LocationTracker({formData, setFormData}) {
    const [location, setLocation] = useState(null);
    const [bottonMessage, setBottoMessage] = useState('Obtener ubicación');

    const handleButtonClick = () => {
        setBottoMessage('Obteniendo ubicación...');
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setFormData({
            ...formData,
            latitude,
            longitude
          })
          setBottoMessage('Obtener ubicación');
        }, (error) => {
          console.error(error);
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };
  
    return (
      <div>
        <MDButton variant="gradient" color="error"
            onClick={handleButtonClick} fullWidth>
            {bottonMessage}
        </MDButton>
        {location || formData.latitude ? (
          <LoadScript googleMapsApiKey="AIzaSyDtEyz57YMYuFoIn5CGFBxsGWXJwBDUpXY">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ 
                    lat: formData.latitude ? parseFloat(formData.latitude) : location.latitude, 
                    lng: formData.longitude  ? parseFloat(formData.longitude)  : location.longitude }}
              zoom={11}
            >
              <Marker position={{ 
                    lat: formData.latitude ? parseFloat(formData.latitude) : location.latitude, 
                    lng: formData.longitude  ? parseFloat(formData.longitude)  : location.longitude
                }} />
            </GoogleMap>
          </LoadScript>
        ) : (
          <small>Presione el botón para obtener la ubicación.</small>
        )}
      </div>
    );
  };

LocationTracker.propTypes = {
    formData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    setFormData: PropTypes.func.isRequired,
}

export default LocationTracker;