/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import imageCompression from 'browser-image-compression';

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';


import Select from 'react-select';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";


import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';
import { useSearchParams, useLocation } from 'react-router-dom';

import Modal from 'react-modal';
import {Post,Put, Del, Get, PostGuest} from "../../tools/Helper";
import Message from "../../tools/Message";


import canvasPreview from '../certificados/canvasPreview'
import 'react-image-crop/dist/ReactCrop.css';
import useDebounceEffect from '../certificados/useDebounceEffect';

import backImg from "../../tools/credencial_fondo.jpg";
import imageIne from "../../assets/images/ine.jpg"
import imageFoto from "../../assets/images/foto-correcta.jpeg"

import Cursos from './Cursos';

function ServicioForm({editar, data, setShow, isGuest, getLista, cursos, ocupaciones, clientes, puestos}) {


  /* CROP */
  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  } 
  const [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState(data);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const [editarFoto, setEditarFoto] = useState(false)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(0.90)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(9 / 12)

  const imgRefIne = useRef(null)
  const [cropIne, setCropIne] = useState()
  const [completedCropIne, setCompletedCropIne] = useState()
  const previewCanvasRefIne = useRef(null)
  const [aspectIne, setAspectIne] = useState(16 / 10)
  const [scaleIne, setScaleIne] = useState(1)

  const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({status: null, message: ""});
  const [dataFinal, setDataFinal] = useState(null);
  const [qr, setQr] = useState(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [messageSizeFile, setMessageSizeFile] = useState({
    status: false,
    type: ''
  });

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onImageLoadIne(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCropIne(centerAspectCrop(width, height, aspectIne))
    }
  }

  async function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      setStatus({
        status: 'warning',
        message: "Comprimiendo imagen...",
      });
      const imageFile = e.target.files[0];
      console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 720,
        useWebWorker: true,
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        console.log(compressedFile);
  
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile); 
        reader.onloadend = function() {
          const imgBase64data = reader.result;                
          setFormData({...formData, evidencias:{...formData.evidencias, foto: imgBase64data, foto_nombre: e.target.files[0].name}});
          setStatus({
            status: null,
            message: "",
          });
        }
  
      } catch (error) {
        console.log(error);
      }
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0,
        )
        
      }
    },
    100,
    [completedCrop, scale, 0],
  );

  useDebounceEffect(
    async () => {
      if (
        completedCropIne?.width &&
        completedCropIne?.height &&
        imgRefIne.current &&
        previewCanvasRefIne.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRefIne.current,
          previewCanvasRefIne.current,
          completedCropIne,
        )
      }
    },
    100,
    [completedCropIne],
  );


  /* */

  const sexos = [
    {
      'value': "Masculino",
      'text': 'Masculino'
    },
    {
      'value': "Femenino",
      'text': 'Femenino'
    },
    {
      'value': "Prefiero no decirlo",
      'text': 'Prefiero no decirlo'
    }
  ];

  const escolaridades = [
    {
      'value': "Primaria",
      'text': 'Primaria'
    },
    {
      'value': "Secundaria",
      'text': 'Secundaria'
    },
    {
      'value': "Bachillerato",
      'text': 'Bachillerato'
    },
    {
      'value': "Carrera Tecnica",
      'text': 'Carrera Tecnica'
    },
    {
      'value': "Licenciatura",
      'text': 'Licenciatura'
    },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const emptyCursosArray = [{id_curso:''}];
  const [cursosArray, setCursosArray] = useState(emptyCursosArray);

  const setCurArr = (d) =>{
    setCursosArray(d);
  }

  const getDataCert = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get(`cursantes/${formData.id_cursante}`);

    if(request.status === "success"){
        const temp = request.data;
        if(!temp.evidencias){
          temp.evidencias = {
            curp: '',
            curp_nombre: '',
            foto: '',
            foto_nombre: '',
            ine: '',
            ine_nombre: '',
          };
        }

        if(temp.cursos.length > 0){
          setCursosArray(temp.cursos);
          console.log(temp.cursos);
        }
        if(clientes.length === 1){
          const newData = {
            ...temp,
            cliente_arr: clientes[0]
          }
          setFormData(newData);
        }else{
          const newData = {
            ...temp,
            cliente_arr: temp.cliente
          }
          setFormData(newData);
        }

    }else{
      setStatus(request);
    }

    setLoading(false);
  }

  useEffect(()=>{
    if(clientes.length === 1){
      setFormData({
        ...formData,
        id_cliente: clientes[0].id_cliente
      })
    }
    if(editar){
      getDataCert();
    }
  },[]);


  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
      if(e.target.name === 'nombre_completo' || e.target.name === 'curp') {
        setFormData({...formData, [e.target.name]:(e.target.value).toUpperCase()});
        return;
      }
      setFormData({...formData, [e.target.name]:e.target.value});
      
    }else if(value === "id_ocupacion"){
      setFormData({...formData, [value]:e.id_ocupacion, [name]:e});
    }else if(value === "id_cliente"){
      setFormData({...formData, [value]:e.id_cliente, [name]:e});
    }else if(value === "id_puesto_brigada"){
      setFormData({...formData, [value]:e.id_puesto_brigada, [name]:e});
    }else{
      setFormData({...formData, [value]:e.value, [name]:e});
    }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const handleCheck = (e) =>{
    setFormData({...formData, acepta:e.target.checked});
  }

  const doRequest = async e =>{
    if(formData.puesto === '' ||
    formData.telefono === '' || 
    formData.sexo === '' || formData.edad === '' || 
    formData.tipo_sangre === '' ||
    formData.escolaridad === '' || formData.calle_num === '' || 
    formData.nombre_emergencia === '' || formData.telefono_emergencia === '' || 
    formData.municipio === '' || formData.estado === ''){
      setStatus({status: 'error', message: "Todos los campos son obligatorios"});
      return;
    }
    console.log(formData)
    if(!formData.id_cliente){
        setStatus({status: 'error', message: "Por favor seleccione la empresa"});
        return;
    }

    if(messageSizeFile.status){
      setStatus({status: 'error', message: "Existen archivos que exceden el máximo permitido 2M"});
      setLoading(false);
      return;
    }
  
    
    setStatus({status: null, message: ""});
    setLoading(true);
    const tempData = {
      ...formData,
    };

    // if(cursosArray === 0){
    //   setStatus({status: 'error', message: "Debe elegir al menos un curso"});
    //   setLoading(false);
    //   return;
    // }

    // if(cursosArray[0].id_curso === ''){
    //     setStatus({status: 'error', message: "Debe elegir al menos un curso"});
    //     setLoading(false);
    //     return;
    // }
    
    tempData.cursos = cursosArray;
    let request = null;
    let newData = {};
    const tokenUserExternal = searchParams.get("tk");
    const isGuesCustomer = searchParams.get("empresa");

    console.log(isGuesCustomer);

    if(isGuest && isGuesCustomer !== 'true'){
      console.log('1');
      newData = {
        ...formData,
        token_guest: tokenUserExternal
      }
      request = await PostGuest('cursante-externo-curso',newData);
      setStatus(request);
    }else if(isGuest && isGuesCustomer === 'true'){
      console.log('2')
      newData = {
        ...formData,
        token_guest: tokenUserExternal
      }
      request = await PostGuest('cursante-p',newData);
      setStatus(request);
    }
    else{
      console.log('3')
      request = editar ? await Put(`cursantes/${formData.id_cursante}`,formData) : await Post('cursantes',formData) ;
      if(request.status === "success"){
        await getLista();
        if(editar){
          setFormData(tempData);
        }
      }
    }

    setStatus(request);
    setLoading(false);
  }

  const deleteCursante = async e =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`cursantes/${formData.id_curso}`);

    if(request.status === "success"){
      await getLista();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const getCurso = cursos.find(option => parseInt(option.id_curso,10) === parseInt(formData.id_curso,10));
  const getSexo = sexos.find(option => option.value === formData.sexo);
  const getEscolaridad = escolaridades.find(option => option.value === formData.escolaridad);
  const getOcupacion = ocupaciones.find(option => parseInt(option.id_ocupacion,10) === parseInt(formData.id_ocupacion,10));
  const getCliente = clientes.find(option => parseInt(option.id_cliente,10) === parseInt(formData.id_cliente,10));
  const getPuestos = puestos ? puestos.find(option => parseInt(option.id_puesto_brigada,10) === parseInt(formData.id_puesto_brigada,10)) : null;

  const handleFile = async (event, name) => {
    setStatus({
      status: 'warning',
      message: "Comprimiendo imagen...",
    });
    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      console.log(compressedFile);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile); 
      reader.onloadend = function() {
        const imgBase64data = reader.result;                
        setFormData({...formData, evidencias:{...formData.evidencias, [name]: imgBase64data, [`${name}_nombre`]: event.target.files[0].name}});
        setStatus({
          status: null,
          message: "",
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  const borrarEvidencia = (name) => {
    setFormData({...formData, evidencias:{...formData.evidencias, [name]: '', [`${name}_nombre`]: ''}});
  }

  const descargarEvidencia=(name, blob)=>{
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
      const base64Evidencia = reader.result;                
      const a = document.createElement("a"); // Create <a>
      a.href =  base64Evidencia; // Image Base64 Goes here
      a.download = formData.evidencias[`${name}_nombre`]; // File name Here
      a.click();
    }
  }

  const descargarEvidenciaFoto = (name) =>{
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      descargarEvidencia(name, blob);
    });
  }

  const descargarEvidenciaIne = (name) =>{
    if (!previewCanvasRefIne.current) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRefIne.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      descargarEvidencia(name, blob);
    });
  }

  const aplicarRecorteEvidencia=()=>{
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = function() {
        const base64Evidencia = reader.result;                
        setFormData({
          ...formData, 
          evidencias:
            {
              ...formData.evidencias, 
              foto: base64Evidencia, 
            }
        });
      }
    });
  }

  const aplicarRecorteEvidenciaIne= async ()=>{
    if (!previewCanvasRefIne.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRefIne.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = function() {
        const base64Evidencia = reader.result;                
        console.log(base64Evidencia)
        setFormData({
          ...formData, 
          evidencias:
            {
              ...formData.evidencias, 
              ine: base64Evidencia, 
            }
        });
      }
    });
  }

	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  {
                    userData && userData.user.admin === 0 ?
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Paso 2: Completa tu perfil
                    </MDTypography>
                    :
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      {editar ? "Actualizar" : "Agregar"} Participante
                    </MDTypography>
                  }
                </Grid>
                {
                  ! isGuest ?
                    <Grid item xs={6} textAlign="right" >
                      <MDButton variant="gradient" color="light" 
                        onClick={()=>setShow(true)}>
                        <Icon fontSize="small">arrow_back</Icon>
                          &nbsp;Regresar a la lista
                      </MDButton>
                    </Grid>
                  : null
                }
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : <>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Nombre y apellidos" name="nombre_completo"
                          error={verifyError("nombre_completo")} errorMsg={getError("nombre_completo")}
                          value={formData.nombre_completo} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="CURP" name="curp"
                          error={verifyError("curp")} errorMsg={getError("curp")}
                          value={formData.curp} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <Select className={`z-10 ${verifyError("id_ocupacion") ? "border-red" : ""}`}
                          options={ocupaciones} placeholder="Ocupación especifica"
                          value={getOcupacion} onChange={(e) => handleChange(e,"ocupacion_arr","id_ocupacion")}
                          getOptionValue={(option) => `${option.id_ocupacion}`}
                          getOptionLabel={(option) => `${option.nombre}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Puesto" name="puesto"
                          error={verifyError("puesto")} errorMsg={getError("puesto")}
                          value={formData.puesto} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Correo" name="correo"
                          error={verifyError("correo")} errorMsg={getError("correo")}
                          value={formData.correo} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Telefono" name="telefono"
                          error={verifyError("telefono")} errorMsg={getError("telefono")}
                          value={formData.telefono} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <Select className={`z-101 ${verifyError("sexo") ? "border-red" : ""}`}
                          options={sexos} placeholder="Sexo"
                          value={getSexo} onChange={(e) => handleChange(e,"sexo_arr","sexo")}
                          getOptionValue={(option) => `${option.value}`}
                          getOptionLabel={(option) => `${option.text}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Edad" name="edad"
                          error={verifyError("edad")} errorMsg={getError("edad")}
                          value={formData.edad} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Tipo de sangre" name="tipo_sangre"
                          error={verifyError("tipo_sangre")} errorMsg={getError("tipo_sangre")}
                          value={formData.tipo_sangre} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <Select className={`z-10 ${verifyError("sexo") ? "border-red" : ""}`}
                          options={escolaridades} placeholder="Escolaridad"
                          value={getEscolaridad} onChange={(e) => handleChange(e,"escolaridad_arr","escolaridad")}
                          getOptionValue={(option) => `${option.value}`}
                          getOptionLabel={(option) => `${option.text}`}
                        />
                    </Grid>
                    <Grid item xs={12} mt="auto">
                      Dirección actual donde vive:
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Calle y Numero" name="calle_num"
                          error={verifyError("calle_num")} errorMsg={getError("calle_num")}
                          value={formData.calle_num} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Colonia" name="colonia"
                          error={verifyError("colonia")} errorMsg={getError("colonia")}
                          value={formData.colonia} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Municipio/Alcaldía" name="municipio"
                          error={verifyError("municipio")} errorMsg={getError("municipio")}
                          value={formData.municipio} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Estado" name="estado"
                          error={verifyError("estado")} errorMsg={getError("estado")}
                          value={formData.estado} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} mt="auto">
                      En caso de emergencia llamar a:
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Nombre" name="nombre_emergencia"
                          error={verifyError("nombre_emergencia")} errorMsg={getError("nombre_emergencia")}
                          value={formData.nombre_emergencia} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <MDInput type="text" label="Telefono" name="telefono_emergencia"
                          error={verifyError("telefono_emergencia")} errorMsg={getError("telefono_emergencia")}
                          value={formData.telefono_emergencia} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <p style={{ fontSize: 11 }}>* Esta casilla aplica solo para brigadistas de protección civil</p>
                      <Select className={`z-102 ${verifyError("id_puesto_brigada") ? "border-red" : ""}`}
                          options={puestos} placeholder="Puesto de la brigada"
                          value={getPuestos} onChange={(e) => handleChange(e,"puesto_arr","id_puesto_brigada")}
                          getOptionValue={(option) => `${option.id_puesto_brigada}`}
                          getOptionLabel={(option) => `${option.descripcion}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <Select className={`z-102 ${verifyError("empresa") ? "border-red" : ""}`}
                          options={clientes} placeholder="Empresa"
                          value={getCliente} onChange={(e) => handleChange(e,"cliente_arr","id_cliente")}
                          getOptionValue={(option) => `${option.id_cliente}`}
                          getOptionLabel={(option) => `${option.nombre_comercial}`}
                        />
                    </Grid>
                    {/* <Grid item xs={12} mt="auto">
                      Carga tu INE y tu fotografía:
                    </Grid>
                  	<Grid item xs={12} md={6}  mt="auto">
                        <MDTypography variant="h6">
                          INE
                        </MDTypography>
                        <MDTypography variant="h6" display="inline" pt={3}> 
                          {
                            formData.evidencias && formData.evidencias.ine_nombre && formData.evidencias.ine_nombre !== "" ? 
                            formData.evidencias.ine_nombre : ''
                          }
                          {
                            formData.evidencias && formData.evidencias.ine_nombre && formData.evidencias.ine_nombre !== "" ? 
                            <MDBox>
                              <ReactCrop
                                  crop={cropIne}
                                  onChange={(_, percentCrop) => setCropIne(percentCrop)}
                                  onComplete={(c) => setCompletedCropIne(c)}
                                >
                                  <img
                                    ref={imgRefIne}
                                    alt="Crop me"
                                    src={formData.evidencias.ine}
                                    style={{ transform: `scale(${scaleIne}) rotate(${rotate}deg)`, maxHeight: "300px"}}
                                    onLoad={onImageLoadIne}
                                  />
                                </ReactCrop>
                              <Grid container xs={8} spacing={1} pl={1} pt={1}>
                                <Grid item xs={12}>
                                  <MDBox>
                                    <MDButton variant="gradient" color="success" onClick={()=>aplicarRecorteEvidenciaIne()}
                                      mx={4} fullWidth>
                                      Aplicar recorte
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                                <Grid item xs={8}>
                                  <MDBox mt={1}>
                                    <MDButton variant="gradient" color="primary" onClick={()=>descargarEvidenciaIne('ine')}
                                      mx={4} fullWidth>
                                      Descargar
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                                <Grid item xs={4}>
                                  <MDBox mt={1}>
                                    <MDButton variant="gradient" color="error" onClick={()=>borrarEvidencia('ine')}
                                      mx={4} fullWidth>
                                      <Icon fontSize="small">delete_forever</Icon>
                                      Borrar
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox> : 
                            <Grid item xs={12} md={12} className="containerExamples"  spacing={3} mt="auto">
                                <Grid item xs={12} md={6}  mt="auto" style={{ float: 'left' }}>
                                  <div className='file-select'>                              
                                    <input type='file' id="src-ine" accept="image/*" className="btn btn-primary" onChange={(e)=>handleFile(e, 'ine')}/>
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={6} mt="auto" style={{ float: 'left', justifyContent: 'start' }}>
                                  <img
                                    alt="bg"
                                    src={imageIne}
                                    width={200}
                                  />
                              </Grid>
                            </Grid>
                          }
                        </MDTypography>
                      </Grid> */}
                      <Grid item xs={12} md={12}>
                        <MDTypography variant="h6" style={{ marginBottom: 10 }}>
                          FOTO
                        </MDTypography>
                        <MDTypography variant="h6" display="inline" pt={3}> 
                          {
                            formData.evidencias && formData.evidencias.foto_nombre !== "" && formData.evidencias.foto_nombre ? 
                            formData.evidencias.foto_nombre : ''
                          }
                        </MDTypography>
                        <Grid item xs={12}>
                          <Grid container spacing={1} pb={1}>
                            {formData.evidencias && !!formData.evidencias.foto ?
                              <MDBox>
                                <Grid item xs={12} mt="auto">
                                  <img
                                  ref={imgRef}
                                  alt="Fotografía"
                                  src={formData.evidencias.foto}
                                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: "300px"}}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <MDButton variant="gradient" color="error" onClick={()=>borrarEvidencia('foto')}
                                    mx={4} fullWidth>
                                    <Icon fontSize="small">delete_forever</Icon>
                                    Borrar
                                  </MDButton>
                                </Grid>
                                {/* <ReactCrop
                                  crop={crop}
                                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                                  onComplete={(c) => setCompletedCrop(c)}
                                  aspect={aspect}
                                  disabled
                                >
                                  <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={formData.evidencias.foto}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: "300px"}}
                                    onLoad={onImageLoad}
                                  />
                                </ReactCrop>
                                <Grid container spacing={1} pl={1} pt={1}>
                                  <Grid item xs={6}>
                                    <MDButton variant="gradient" color="info" onClick={()=>setScale(scale + 0.10)} mx={4} fullWidth>
                                      Acercar
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MDButton variant="gradient" color="info" onClick={()=>setScale(scale - 0.10)} mx={4} fullWidth>
                                      Alejar
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MDButton variant="gradient" color="success" onClick={()=>aplicarRecorteEvidencia()}
                                      mx={4} fullWidth>
                                      Aplicar Recorte
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <MDButton variant="gradient" color="primary" onClick={()=>descargarEvidenciaFoto('foto')}
                                      mx={4} fullWidth>
                                      Descargar
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <MDButton variant="gradient" color="error" onClick={()=>borrarEvidencia('foto')}
                                      mx={4} fullWidth>
                                      <Icon fontSize="small">delete_forever</Icon>
                                      Borrar
                                    </MDButton>
                                  </Grid>
                                </Grid>                               */}
                              </MDBox> : 
                              <Grid container item xs={12} md={12} className="containerExamplesSubir"  spacing={3} mt="auto">
                                <Grid item xs={12} md={12} mt="auto" style={{ float: 'left' }}>
                                  <p style={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}>Antes de subir tu foto, asegúrate que esté recortada como se muestra en el ejemplo.</p>
                                </Grid>
                                <Grid item xs={12} md={12} mt="auto" style={{ float: 'left', justifyContent: 'start' }}>
                                  <img
                                    alt="bg"
                                    src={imageFoto}
                                    width={200}
                                  />
                                </Grid>
                                <Grid item xs={12} md={12}  mt="auto" style={{ float: 'left' }}>
                                  <div id="src-foto-subir" className='file-select'>                              
                                    <input type='file' accept="image/*" onChange={(e)=>onSelectFile(e)} />
                                  </div>
                                </Grid>
                            </Grid>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                      {!!completedCrop && (
                          <canvas
                            ref={previewCanvasRef}
                            style={{
                              objectFit: 'contain',
                              width: "110px",
                              height: "145px",
                              position: "fixed",
                              top: 0,
                              right: "-300px",
                              margin: "auto",
                            }}
                          />
                        )}
                      {!!completedCropIne && (
                          <canvas
                            ref={previewCanvasRefIne}
                            style={{
                              objectFit: 'contain',
                              width: "110px",
                              height: "145px",
                              position: "fixed",
                              top: 0,
                              right: "-300px",
                              margin: "auto",
                            }}
                          />
                        )}
                    <Grid item xs={6} />
                    {/* <Grid item xs={12} mb={2}>
                      <Divider />
                      <MDTypography variant="h5">
                        Cursos a solicitar
                      </MDTypography>
                    </Grid> */}
                    </>}
              </Grid>
              { !loading ? 
                <>
                {/* <Cursos cursosArray={cursosArray} setCursosArray={setCurArr} 
                  catalogoCursos={cursos} errors={"errors" in status?status.errors:null}
                  setStatus={setStatus} /> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <div className="form-check">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                            <input className="form-check-input" type="checkbox" name="check" checked={formData.acepta} onChange={handleCheck} id="flexCheckDefault" />
                            <MDTypography variant="caption">
                            *Autoriza usted el uso de sus Datos para fines Internos de Difusión de Nuestros servicios y promociones, los cuales no serán vendidos ni transferidos a un tercero.
                            </MDTypography>
                            </label>
                        </div>
                    </Grid>

                    <Grid item xs={editar ? 6 : 12}>
                      <MDButton variant="gradient" color="info" disabled={loading}
                        onClick={()=>doRequest()} fullWidth>
                        {editar ? "Actualizar" : "Agregar"}
                      </MDButton>
                      <Message status={status} />
                    </Grid>
                    {/* {editar ? 
                    <Grid item xs={6}>
                      <MDButton variant="gradient" color="warning" disabled={loading}
                        onClick={()=>openModal()} fullWidth>
                        Eliminar
                      </MDButton>
                    </Grid> : ""} */}
                  </Grid>
                  </> : ""
                }
            </MDBox>
          </Card>
          <Modal
            isOpen={modalIsOpen}
            contentLabel="¿Seguro que desea eliminar el curso?"
            style={customStyles}
          >
            <Grid container spacing={2} p={4}>
              <h2>¿Seguro que desea eliminar el curso?</h2>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="info" disabled={loading}
                  onClick={()=>closeModal()} fullWidth>
                  Cancelar
                </MDButton>
              </Grid>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="warning" disabled={loading}
                  onClick={()=>deleteCursante()} fullWidth>
                  Eliminar
                </MDButton>
              </Grid>
            </Grid>
          </Modal>
        </MDBox>
	);
}

ServicioForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  isGuest: PropTypes.bool.isRequired,
  cursos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  ocupaciones: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  clientes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  puestos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default ServicioForm;