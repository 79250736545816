/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";


import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";

import { useSearchParams, useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import Message from "../../tools/Message";

import FormRegistro from "./Form";
import FormActualizar from "../cursantes/Form";

import {Get, Del, GetGuest} from "../../tools/Helper";

function Curso() {

  const emptyForm = {
    nombre_completo:'',
    curp:'',
    ocupacion:'',
    puesto:'',
    correo:'',
    sexo:'',
    edad:'',
    tipo_sangre:'',
    empresa:'',
    escolaridad:'',
    calle_num:'',
    municipio:'',
    estado:'',
    nombre_emergencia:'',
    telefono_emergencia:'',
    telefono:'',
    sexo_arr: [],
    escolaridad_arr: [],
    id_ocupacion: "",
    evidencias: {
      curp: '',
      curp_nombre: '',
      foto: '',
      foto_nombre: '',
      ine: '',
      ine_nombre: '',
    },
    cursos: [],
    acepta: false,
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [selectedDelete, setSelectedDelete] = useState(null);

  const [cursos, setCursos] = useState([]);
  const [ocupaciones, setOcupaciones] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [puestos, setPuestos] = useState([]);

  const [isGuest, setIsGuest] = useState(false);

  const editarButton = (row) =>{
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const crearButton = () =>{
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const tokenUserExternal = searchParams.get("tk");
  const isGuesCustomer = searchParams.get("empresa");

  console.log(isGuesCustomer)

  const navigate = useNavigate();

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));

    const requestCat = await Get(`cursante-externo-catalogos`);
    if(requestCat.status === "success"){
      setCursos(requestCat.data.cursos);
      setOcupaciones(requestCat.data.ocupaciones);
      setClientes(requestCat.data.clientes);
      setPuestos(requestCat.data.puestos_brigadas)
    }else{
      setStatus({status:requestCat.status, message:"Error."});
    }

    if(!user.user.id_cursante){
      setLoading(false);
      crearButton();
      return;
    }

    const request = await Get(`cursante-externo/${user.user.id_cursante}`);

    if(request.status === "success"){
      setData(request.data.cursantes);
      setCursos(request.data.cursos);
      setOcupaciones(request.data.ocupaciones);
      setClientes(request.data.clientes);
      setPuestos(request.data.puestos_brigadas)
      if(!request.data.cursantes[0].curp || request.data.cursantes[0].curp === ''){
        setStatus({status: "warning", message: "Por favor complete sus datos."});
      }
    }else{
      setStatus({status:"error", message:"Error."});
    }

    setSelectedData(emptyForm);
    setLoading(false);
  }

  const getDataGuest = async () =>{
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await GetGuest(`cursante-invitado/${tokenUserExternal}`);

    if(request.status === "success"){
      setCursos(request.data.cursos);
      setOcupaciones(request.data.ocupaciones);
      setClientes(request.data.clientes);
      setPuestos(request.data.puestos_brigadas)
    }else{
      setStatus({status:"error", message:"No tienes permiso."});
    }

    setSelectedData(emptyForm);
    setLoading(false);

  }
  const validateUserExternal = () =>{
    const user = JSON.parse(localStorage.getItem('user'));

    if(tokenUserExternal){
      setIsGuest(true);
      crearButton();
      getDataGuest();
      return;
    }

    if(!user){
      navigate("/login");
    }
    
    getData();
  }

  useEffect(()=>{
    validateUserExternal();
  },[]);


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete(null);
  }

  function openModal(idPregunta) {
    setIsOpen(true);
    setSelectedDelete(idPregunta);
  }

  const deleteCurso = async () =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`cursantes/${selectedDelete}`);

    if(request.status === "success"){
      await getData();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const getCursos = (row) => row.map((el)=>
    <p>{el.curso.nombre}</p>
  );

  const editButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const deleteButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>openModal(row.id_pregunta)}><Icon>deleteForever</Icon></MDButton>;

  const columns = [
    {
        name: 'Nombre completo',
        selector: row => row.nombre_completo,
        grow:3,
        sortable: true,
    },
    {
        name: 'CURP',
        selector: row => row.curp,
        wrap:true,
        grow:2,
    },
    {
        name: 'Correo',
        selector: row => row.correo,
        grow:2
    },
    {
        name: '',
        selector: row => editButton(row),
        grow:0,
        compact: true,
    }
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                        Mi perfil
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    {
                      data.length === 0 ?
                        <MDButton variant="gradient" color="light" 
                          onClick={()=>crearButton()}>
                          <Icon fontSize="small">add</Icon>
                          &nbsp;Capturar mis datos
                        </MDButton>
                      : null
                    }
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                  :
                  <MDBox>
                    <Message status={status} />
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationComponentOptions={{
                        rowsPerPageText: 'Participantes por página:', 
                        rangeSeparatorText: 'de', 
                        noRowsPerPage: false, 
                        selectAllRowsItem: true, 
                        selectAllRowsItemText: 'Todos'
                      }}
                      noDataComponent={<MDBox py={5}>Debe capturar sus datos.</MDBox>}
                    />
                  </MDBox>
                  }
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          {
            (isGuest && isGuesCustomer === 'true') || !isGuest ?
            <Collapse in={!show}  timeout={50}>
            { !show ? 
              <FormActualizar data={selectedData} editar={editar} setShow={setShow} isGuest={isGuest}
              getLista={getData} cursos={cursos} ocupaciones={ocupaciones} clientes={clientes} puestos = {puestos} statusMessage={status} /> : ""
            }
            </Collapse>
            :
            <Collapse in={!show}  timeout={50}>
            { !show ? 
              <FormRegistro data={selectedData} editar={editar} setShow={setShow} isGuest={isGuest}
              getLista={getData} cursos={cursos} ocupaciones={ocupaciones} clientes={clientes} puestos = {puestos} statusMessage={status} /> : ""
            }
            </Collapse>

          }

        </Fade>
      </MDBox>
        <Modal
          isOpen={modalIsOpen}
          contentLabel="¿Seguro que desea eliminar el cursante?"
          style={customStyles}
        >
          
          <Grid container spacing={2} p={4}>
            <h2>¿Seguro que desea eliminar el cursante?</h2>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="info" disabled={loading}
                onClick={()=>closeModal()} fullWidth>
                Cancelar
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="warning" disabled={loading}
                onClick={()=>deleteCurso()} fullWidth>
                Eliminar
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Curso;