import {useEffect, useState} from 'react';
import MDBox from "components/MDBox";
import PropTypes from 'prop-types';

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

function Message({status}) {

	const [show, setShow] = useState(false);
	const [message, setMessage] = useState("");

	useEffect(()=>{
		if(status.status !== null){
			setShow(true);
			if('errors' in status){
				let mensaje = "";
				Object.keys(status.errors).forEach(key =>{
					mensaje += `${status.errors[key]}\n`;
				});
				setMessage(mensaje);
			}else{
				setMessage(status.message);
			}
		}else{
			setShow(false);
		}
	},[status,setShow,setMessage]);

	const getStatus = (statusMessage) =>{
		let label = 'Error';

		if(statusMessage === 'success'){
			label = 'Éxito';
		}else if( statusMessage === 'warning'){
			label = 'Aviso'
		}else{
			label = 'Error'
		}
		return label;
	}

	if(status.status !== null){
		return (<Fade in={show} timeout={800}>
	          		<Collapse in={show}  timeout={50}>
		          		<MDBox pb={3}>
								<Alert severity={status.status} onClose={() => setShow(false)}>
							  	<AlertTitle>{getStatus(status.status)}</AlertTitle>
							  	<p className="pre-line">{message}</p>
							</Alert>
						</MDBox>
					</Collapse>
	   			</Fade>);
	}
	
	return "";
}

Message.propTypes = {
  status: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default Message;