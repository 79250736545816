
import React, { useState, useEffect, useRef } from "react";

import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Select from 'react-select';
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import Modal from 'react-modal';

import { useNavigate } from "react-router-dom";

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import {Del, Get, Post, Put} from "../../tools/Helper";
import Message from "../../tools/Message";

function Evaluaciones() {
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [dataEvaluation, setDataEvaluation] = useState({});
    const [data, setData]= useState([]);
    const [respuestaInput, setRespuestaInput] = useState('');
    const [editRespuesta, setEditRespuesta] = useState({});
    const [editPregunta, setEditPregunta] = useState({});
    const [editPreguntaOrden, setEditPreguntaOrden] = useState({});

    const [status, setStatus] = useState({status: null, message: ""});
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [show, setShow] = useState(true);
  
    const addQuestion = () => {
        if(!newQuestion){
            setStatus({status: 'error', message: "Escriba una pregunta"});
            return;
        }
        
        if(options.length === 0){
            setStatus({status: 'error', message: "Agregue respuestas"});
            return;
        }
        
        if(correctAnswer === ''){
            setStatus({status: 'error', message: "Seleccione una respuesta correcta"});
            return;
        }

        const newQuestionObj = {
          question: newQuestion,
          options,
          correctAnswer
        };
  
        setQuestions([...questions, newQuestionObj]);
        setNewQuestion('');
        setOptions([]);
        setCorrectAnswer('');
    };
  
    const addOption = (option) => {
    if(option === ''){
        setStatus({status: 'error', message: "Escriba una respuesta"});
        return;
    }
    const exists = options.filter(item=>item === option);
    if(exists.length > 0){
        setStatus({status: 'error', message: "Respuesta duplicada"});
        return;
    }

      setOptions([...options, option]);
      setRespuestaInput('');
    };

    const handleChange = (e, name = "", value = "") =>{
        if(name === ""){
            setDataEvaluation({...dataEvaluation, [e.target.name]:e.target.value});
          }else{
            setDataEvaluation({...dataEvaluation, [value]:e[value], [name]:e});
          }
    
        if("errors" in status){
          const verifyName = name === "" ? e.target.name : name;
          if(verifyName in status.errors){
            const temp = status;
            delete temp.errors[verifyName];
            setStatus(temp);
          }
        }
    }

    const handleChangeQuestion = (e, question) =>{
        setEditPregunta({
            id_pregunta_evaluacion: question.id_pregunta_evaluacion,
            pregunta: e.target.value
        })
    }

    const handleChangeQuestionOrder = (e, question) =>{
        setEditPreguntaOrden({
            id_pregunta_evaluacion: question.id_pregunta_evaluacion,
            orden: e.target.value
        })
    }

    const handleChangeOption = (e, option) =>{
        setEditRespuesta({
            id_respuesta_evaluacion: option.id_respuesta_evaluacion,
            descripcion: e.target.value
        })
    }

    const getEvaluaciones = async ()=>{
        setStatus({status: null, message: ""});
        setLoading(true);
    
        const request = await Get(`evaluacion`);
        
        if(request.status === "success"){
          setData(request.data);
        }else{
          setStatus({status:request.status, message:"Error."});
        }
        setLoading(false);
      }


    const saveEvaluacion = async() =>{
        setStatus({status: null, message: ""});
        setLoading(true);
        if(dataEvaluation.titulo === ''){
            setStatus({status: 'error', message: "Título requerido"});
            return;
        }

        const newData = {
            encabezado: dataEvaluation,
            preguntas: questions
        }
    
        const request = await Post(`evaluacion`, newData);
        
        if(request.status === "success"){
            getEvaluaciones();
            setQuestions([]);
            setDataEvaluation({
                titulo: '',
                descripcion: ''
            });
            setStatus(request);
        }else{
          setStatus({status:request.status, message:"Error."});
        }
        setLoading(false);
    }

    const verifyError = (name) =>{
        if("errors" in status){
          if(name in status.errors){
            return true;
          }
        }
    
        return false;
    }
    
    const getError = (name) =>{
        if("errors" in status){
            if(name in status.errors){
            return status.errors[name][0];
            }
    }

    return "";
    }

    useEffect(()=>{
        getEvaluaciones();
    },[])


    const editEvaluacion = (row) =>{

        setDataEvaluation({
            titulo: row.titulo,
            descripcion: row.descripcion,
            tipo: row.tipo,
            id_evaluacion: row.id_evaluacion
        })

        setIsEditing(true);
        const dataQuestions = [];
        row.pregunta_evaluacion.forEach(item=>{
            const correct = item.respuesta_evaluacion.find(option=> parseInt(option.correcto, 10) === 1);
            const dataOptions = [];
            const dataOptionsValue = [];
            item.respuesta_evaluacion.forEach(option=>{
                dataOptions.push(option.descripcion);
                dataOptionsValue.push({
                    option: option.descripcion,
                    id_pregunta_evaluacion: option.id_pregunta_evaluacion,
                    id_respuesta_evaluacion: option.id_respuesta_evaluacion,
                })
            });
            const dataQuestion={
                question: item.pregunta,
                id_pregunta_evaluacion: item.id_pregunta_evaluacion,
                options: dataOptions,
                optionsValue: dataOptionsValue,
                correctAnswer: correct.descripcion,
                order: item.orden
            }
            dataQuestions.push(dataQuestion)
        })
        console.log(dataQuestions)
        setQuestions(dataQuestions)
    }

    const actualizaEncabezado = async ()=>{
        if(! isEditing) {
            return;
        }
        setStatus({status: null, message: ""});

        const request =  await Put(`evaluacion/${dataEvaluation.id_evaluacion}`, dataEvaluation) ;
        if(request.status === "success"){
            setStatus({status: 'success', message: "Respuesta actualizada"});
            getEvaluaciones();
        }
        setStatus(request);
        setLoading(false);
    }

    const actualizaPregunta = async ()=>{
        setStatus({status: null, message: ""});

        if(!editPregunta.id_pregunta_evaluacion){
            return;
        }

        const request =  await Put(`pregunta/${editPregunta.id_pregunta_evaluacion}`, editPregunta) ;
        if(request.status === "success"){
            setStatus({status: 'success', message: "Respuesta actualizada"});
            editEvaluacion(request.data);
        }
        setStatus(request);
        setLoading(false);
    }

    const actualizaPreguntaOrden = async ()=>{
        setStatus({status: null, message: ""});

        if(!editPreguntaOrden.id_pregunta_evaluacion){
            return;
        }

        const request =  await Put(`pregunta/${editPreguntaOrden.id_pregunta_evaluacion}`, editPreguntaOrden) ;
        if(request.status === "success"){
            setStatus({status: 'success', message: "Respuesta actualizada"});
            editEvaluacion(request.data);
        }
        setStatus(request);
        setLoading(false);
    }

    const actualizaRespuesta = async (respuesta, tipo)=>{
        let newData = {};
        if(tipo === 'input'){
            newData = {
                id_respuesta_evaluacion: editRespuesta.id_respuesta_evaluacion,
                descripcion: editRespuesta.descripcion
            }
        }
        if(tipo === 'radio'){
            newData = {
                id_respuesta_evaluacion: respuesta.id_respuesta_evaluacion,
                id_pregunta_evaluacion: respuesta.id_pregunta_evaluacion,
                descripcion: respuesta.descripcion,
                correcto: 1
            }
        }

        setStatus({status: null, message: ""});

        const request =  await Put(`respuesta/${newData.id_respuesta_evaluacion}`, newData) ;
        if(request.status === "success"){
            setStatus({status: 'success', message: "Respuesta actualizada"});
            editEvaluacion(request.data);
        }
        setStatus(request);
        setLoading(false);
    }


    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedDelete, setSelectedDelete] = useState(null);
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        },
      };
    function closeModal() {
        setIsOpen(false);
        setSelectedDelete(null);
      }
    
    function openModal(idPregunta) {
        setIsOpen(true);
        setSelectedDelete(idPregunta);
    }

    const deleteEvaluacion = async () =>{
        closeModal();
        setStatus({status: null, message: ""});
        setLoading(true);
    
        const request = await Del(`evaluacion/${selectedDelete}`);
    
        if(request.status === "success"){
            getEvaluaciones();
        }
        setStatus(request);
        setShow(true);
        setLoading(false);
    }

    const deleteQuestion = async(question) =>{
        setStatus({status: null, message: ""});
        setLoading(true);
    
        const request = await Del(`pregunta/${question.id_pregunta_evaluacion}`);
    
        if(request.status === "success"){
            setStatus({status: 'success', message: "Respuesta actualizada"});
            editEvaluacion(request.data);
        }
        setStatus(request);
        setShow(true);
        setLoading(false);
    }


    const resetForm = async () =>{
        await setIsEditing(false);
        await setQuestions([]);
        await setDataEvaluation({
            titulo: '',
            descripcion: '',
            tipo: '',
            id_evaluacion: 0
        });
    }

    const editButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>editEvaluacion(row)}><Icon>modeEdit</Icon></MDButton>;

    const deleteButton = (row) => <MDButton variant="gradient" 
    color="error" iconOnly onClick={()=>openModal(row.id_evaluacion)}><Icon>deleteForever</Icon></MDButton>;
  
    const tipoEvaluacion = [
        { tipo: 'Diagnóstica y Final'},
        { tipo: 'Servicio'}
    ]
    const getTipoEvaluacion = tipoEvaluacion.find(option => option.tipo === dataEvaluation.tipo);

    const columns = [
        {
            name: 'ID',
            selector: row => row.id_evaluacion,
        },
        {
          name: 'Titulo',
          selector: row => row.titulo,
          wrap: true,
          grow:2
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion,
            wrap: true,
            grow:2
        },
        {
            name: '',
            selector: row => editButton(row),
            grow:0,
            compact: true,
        },
        {
            name: '',
            selector: row => deleteButton(row),
            grow:0,
            compact: true,
        },
      ];

    const tableData = {
        columns,
        data
    };
      
   return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Fade in={show} timeout={800}>
            <Collapse in={show} timeout={50}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2}
                                variant="gradient" bgColor="info"
                                borderRadius="lg" coloredShadow="info"
                                >
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <MDTypography variant="h4" display="inline" mr="auto" color="white">
                                            Evaluaciones
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right" >
                                        <MDButton variant="gradient" color="light" 
                                        onClick={()=>resetForm()}>
                                        <Icon fontSize="small">add</Icon>
                                        &nbsp;Nuevo
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                <MDBox pb={3} px={3}>
                                    <MDBox >
                                        <Message status={status} />
                                        <Grid xs={12} md={12} container pb={3} spacing={1}>
                                            <Grid xs={12} md={4} p={2} spacing={1}>
                                                <MDTypography variant="h5" pt={3} pr={3}>Agregar preguntas</MDTypography>
                                                <Grid item xs={12} md={12} mt="auto">
                                                    <MDTypography variant="caption" pt={3} pr={3}>Título</MDTypography>
                                                    <MDInput type="text" label="" name="titulo"
                                                    error={verifyError("titulo")} errorMsg={getError("titulo")}
                                                    value={dataEvaluation.titulo} onChange={handleChange} onBlur= {()=>actualizaEncabezado()} fullWidth />
                                                </Grid>
                                                <Grid item xs={12} md={12} mt="auto">
                                                    <MDTypography variant="caption" pt={3} pr={3}>Descripción</MDTypography>
                                                    <MDInput type="text" label="" name="descripcion"
                                                    error={verifyError("descripcion")} errorMsg={getError("descripcion")}
                                                    value={dataEvaluation.descripcion} onChange={handleChange} onBlur= {()=>actualizaEncabezado()} fullWidth />
                                                </Grid>
                                                <Grid item xs={12} md={12} mt="auto" style={{ zIndex: 9 }}>
                                                    <MDTypography variant="caption" pt={3} pr={3}>Tipo de evaluación</MDTypography>
                                                    <Select className={`z-101 ${verifyError("tipo") ? "border-red" : ""}`}
                                                    options={tipoEvaluacion} placeholder="Tipo"
                                                    value={getTipoEvaluacion || ''}
                                                    onChange={(e) => handleChange(e,"value","tipo")}
                                                    onBlur= {()=>actualizaEncabezado()}
                                                    getOptionValue={(option) => `${option.tipo}`}
                                                    getOptionLabel={(option) => `${option.tipo}`}
                                                    />
                                                </Grid>
                                                { ! isEditing ? 
                                                    <>
                                                    <MDTypography variant="h5" pt={3} pr={3}>Agregar preguntas</MDTypography>
                                                    <Grid item xs={12} md={12} mt="auto">
                                                        <MDTypography variant="caption" pt={3} pr={3}>Nueva pregunta</MDTypography>
                                                        <MDInput type="text" label="Nueva pregunta" name="newQuestion"
                                                        error={verifyError("newQuestion")} errorMsg={getError("newQuestion")}
                                                        value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={12} spacing={1} mt="auto">
                                                        <MDTypography variant="caption" pt={3} pr={3}>Respuesta</MDTypography>
                                                        <MDInput type="text" label="Opcion" id="option" name="option"
                                                        error={verifyError("option")} value={respuestaInput} onChange={(e)=>setRespuestaInput(e.target.value)} errorMsg={getError("option")}
                                                        fullWidth />
                                                        <Grid ml={5} p={2}>
                                                            {
                                                                options.map(item=>(
                                                                    <p className={item === correctAnswer ? "correct-option" : ""} style={{ fontSize: 14 }}>
                                                                        - {item}
                                                                    </p> 
                                                                ))
                                                            }
                                                        </Grid>
                                                        <MDButton md={4} variant="gradient" color="light" style={{ marginTop: 5 }}
                                                            onClick={() => addOption(document.getElementById('option').value)}
                                                            fullWidth >
                                                            Agregar opción
                                                        </MDButton>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} mt="auto" style={{ zIndex: 9 }}>
                                                        <MDTypography variant="caption" pt={3} pr={3}>Respuesta correcta</MDTypography>
                                                        <select className= "select-custom" id="correct-answer" value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)}>
                                                            <option value="">Seleccione una respuesta</option>
                                                            {options.map((option, index) => (
                                                                <option key={option} value={option}>{option}</option>
                                                            ))}
                                                        </select>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} mt={5} style={{ zIndex: 9 }}>
                                                        <MDButton md={4} variant="gradient" color="secondary" style={{ color: 'white', borderRadius: 5 }}
                                                            onClick={addQuestion}
                                                            fullWidth >
                                                            Agregar pregunta <Icon fontSize="medium">add</Icon>
                                                        </MDButton>
                                                    </Grid>
                                                    </>
                                                : null
                                                }
                                            </Grid>
                                            { loading ? 
                                                <Grid xs={12} md={6} container spacing={1} p={5} >
                                                    <Grid item xs={12} md={12} mt={5} style={{ zIndex: 9 }}>
                                                        <MDBox item display="block" fullWidth textAlign="center" p={5}>
                                                            <CircularProgress size="10rem" /> 
                                                            <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                                : 
                                            <Grid xs={12} md={8} spacing={1} p={5} >
                                                <MDTypography variant="h5" pt={3} pr={3}>Vista previa</MDTypography>
                                                <Grid item xs={12} md={12} mt={5} style={{ zIndex: 9 }}>
                                                    <p style={{ fontSize: 10 }} >Tipo: {dataEvaluation.tipo}</p>
                                                    <MDTypography variant="p" style={{fontWeight: 'bold'}}  xs={12} md={12}>{dataEvaluation.titulo}</MDTypography>
                                                    <p style={{ fontSize: 12 }} >{dataEvaluation.descripcion}</p>
                                                    {questions.map((question, index) => (
                                                    <div style={{ paddingTop: 10 }} key={question.id_pregunta_evaluacion}>
                                                        {
                                                            isEditing ?
                                                            <>
                                                                <Icon style={{ cursor: 'pointer' }} onClick={()=>deleteQuestion(question)} color="error">deleteForever</Icon>
                                                                <span style={{ fontSize: 15, fontWeight: 'bold' }}> Orden: <input key={question.id_pregunta_evaluacion} type="number" style={{ padding: 2 }} onBlur= {()=>actualizaPreguntaOrden()} onChange={(e)=>handleChangeQuestionOrder(e, question)} defaultValue={question.order} /><input  key={question.id_pregunta_evaluacion} onBlur= {()=>actualizaPregunta()} onChange={(e)=>handleChangeQuestion(e, question)} className="input-inline input-inline-bold" defaultValue={question.question} /></span>
                                                            </>
                                                            :
                                                                <MDTypography  key={question.id_pregunta_evaluacion}  style={{ fontSize: 16, fontWeight: 'bold' }} variant="p" pt={3} pr={3}>{index + 1 }.- {question.question}</MDTypography>
                                                        }
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue={question.correctAnswer}
                                                            name="radio-buttons-group"
                                                            >
                                                        {
                                                        isEditing ?
                                                            question.optionsValue.map((option) => (
                                                                <FormControlLabel 
                                                                    style={{ padding: 0 }} 
                                                                    key="{option.optionsValue}" 
                                                                    value={option.option} 
                                                                    control={<Radio onClick= {()=> actualizaRespuesta(option, 'radio')}  />} 
                                                                    label={<input style={{ width: 600 }} onBlur= {()=>actualizaRespuesta(option, 'input')} onChange={(e)=>handleChangeOption(e, option)} className="input-inline" defaultValue={option.option} />}/>
                                                            ))
                                                            :
                                                            question.options.map((option) => (
                                                                <FormControlLabel style={{ padding: 0 }} key={option} value={option} control={<Radio />} label={<MDTypography style={{ fontSize: 14, fontWeight: 'normal' }} variant="p" pt={3} pr={3}>{option}</MDTypography>}/>
                                                            ))
                                                        }
                                                        </RadioGroup>
                                                    </div>
                                                    ))}
                                                </Grid>
                                                <Grid item xs={12} md={12} mt={5} style={{ zIndex: 9 }}>
                                                    <Message status={status} />
                                                    {
                                                        ! isEditing && dataEvaluation && questions.length > 0 ?
                                                            <MDButton md={4} variant="gradient" color="info" style={{ color: 'white', borderRadius: 5 }}
                                                                onClick={saveEvaluacion}
                                                                fullWidth >
                                                                Guardar Evaluación <Icon fontSize="medium">save</Icon>
                                                            </MDButton>
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>
                                            }
                                        </Grid>
                                    </MDBox>
                                    <Grid>
                                        <MDBox>
                                            <DataTableExtensions
                                            {...tableData}
                                            export={false}
                                            print={false}
                                            filterPlaceholder="Buscar..."
                                            >
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                pagination
                                                paginationComponentOptions={{
                                                rowsPerPageText: 'Cursos por página:', 
                                                rangeSeparatorText: 'de', 
                                                noRowsPerPage: false, 
                                                selectAllRowsItem: true, 
                                                selectAllRowsItemText: 'Todos'
                                                }}
                                                noDataComponent={<MDBox py={5}>No se encontraron cursos.</MDBox>}
                                            />
                                            </DataTableExtensions>
                                        </MDBox>
                                    </Grid>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <Modal
                isOpen={modalIsOpen}
                contentLabel="¿Seguro que desea eliminar la evaluación?"
                style={customStyles}
                > 
                    <Grid container spacing={2} p={4}>
                        <h2>¿Seguro que desea eliminar la evaluación?</h2>
                        <Grid item xs={6}>
                        <MDButton variant="gradient" color="info" disabled={loading}
                            onClick={()=>closeModal()} fullWidth>
                            Cancelar
                        </MDButton>
                        </Grid>
                        <Grid item xs={6}>
                        <MDButton variant="gradient" color="warning" disabled={loading}
                            onClick={()=>deleteEvaluacion()} fullWidth>
                            Eliminar
                        </MDButton>
                        </Grid>
                    </Grid>
                </Modal>
            </Collapse>
        </Fade>
      </MDBox>
      <Divider />
      <Footer />
    </DashboardLayout>
  );
}

export default Evaluaciones;
