/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";

import Modal from 'react-modal';
import OssCard from "../../examples/Cards/InfoCards/OssCard";

import {Get} from "../../tools/Helper";
import Message from "../../tools/Message";


function Index() {

  const [formData, setFormData] = useState([]);

  const handleChange = (e) =>{
    setFormData({...formData, [e.target.name]:e.target.value});
  }

  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [editar, setEditar] = useState(true);

  const editarButton = (row) =>{
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const verButton = (row) =>{
    setSelectedData(row);
    setShow(false);
    setEditar(false);
  }

  const buttonView = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={()=>verButton(row)}><Icon>previewOutlinedIcon</Icon></MDButton>;
  const buttonEdit = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;
  const buttonPdf = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOss, setSelectedOss] = useState(0);
  
  const getData = async e =>{
  
    setLoading(true);

    const request2 = await Get('mis-oss-archivadas');
    if(request2.status === "success"){
      setData(request2.data);
    }else{
      setData([]);
    }
    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  const archivar = async (id) =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);
    setModalIsOpen(false);

    const request = await Get(`archivar-oss/${id}`);
    if(request.status === "success"){
      // setData(request.data);
      setStatus({status:"success", message:"Se ha desarchivado correctamente."});
      getData();
    }


  }

  const abrirModal = (id) =>{
    setSelectedOss(id)
    setModalIsOpen(true);
  }

  const listaOss = () => data.length > 0 ? data.map((el, index)=>
      
      <OssCard
        data={data[index]}
        actionBtn={abrirModal}
      />
      
    ) : <Grid item xs={12}><Card><MDBox p={4}>No se han encontrado OSS archivadas en tu cuenta.</MDBox></Card></Grid>;

  const cargando = () => <Grid item xs={12} lg={12}>
    <Card>
      <MDBox item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
        <CircularProgress size="6.66rem" /> 
        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
      </MDBox>
    </Card>
  </Grid>

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
        
       <MDBox pt={6} pb={3} display="block">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Fade in={show} timeout={800}>
            <Collapse in={show} timeout={50}>
            <MDBox
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Mis OSS
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" px={4}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Total: {data.length}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <Grid container spacing={2} py={4} className=" anim">
                <Grid item xs={12}>
                  <Message status={status} />
                </Grid>
                {loading ? cargando() : listaOss()}
              </Grid>
            </Collapse>
            </Fade>
            <Modal
              isOpen={modalIsOpen}
              contentLabel="¿Seguro que desea archivar la OSS? Puede desarchivarlo en la seccion de 'Archivo'"
              style={customStyles}
            >
            <Grid container spacing={2} p={4}>
              <h2>¿Seguro que desea archivar la OSS? Puede desarchivarlo en la seccion de Archivo</h2>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="info" disabled={loading}
                  onClick={()=>setModalIsOpen(false)} fullWidth>
                  Cancelar
                </MDButton>
              </Grid>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="warning" disabled={loading}
                  onClick={()=>archivar(selectedOss)} fullWidth>
                  Eliminar
                </MDButton>
              </Grid>
            </Grid>
          </Modal>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Index;
