
import React, { useState, useEffect, useRef } from "react";

import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

import { useNavigate, useParams } from "react-router-dom";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Programar from './Programar'

import {Get, Post} from "../../tools/Helper";
import Message from "../../tools/Message";

function ProgramarContainer() {
  const { idCursoProgramado } = useParams();
  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestringStart = `${today.getFullYear()-2}-01-01`;
  const datestringEnd = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+2}-${zeroDate}${today.getDate()}`;

  const scrollRef = useRef(React.createRef());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataDetCurso, setDataDetCurso] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [cursantesProgramados, setCursantesProgramados] = useState([]);
  const [instructores, setInstructores] = useState([]);
  const [administradores, setAdministradores] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [clientes, setClientes] = useState([]);
  
  const [dataSearch, setDataSearch] = useState({
    fecha_inicio: datestringStart,
    fecha_fin: datestringEnd,
  });
  const [dataCatalogs, setDataCatalogs] = useState({
    clientes: [],
    cursos: [],
    instructores: [],
  });
  const [status, setStatus] = useState({status: null, message: ""});
  const [show, setShow] = useState(true);

  const emptySearch = {
    fecha_inicio: datestringStart,
    fecha_fin: datestringEnd,
    id_cliente: null,
    id_curso: null,
    id_firmante: null,
    municipio_alcaldia: '',
    estado: '',
    estatus: null
  }

  const handleChange = (e, name = "", value = "") =>{
  
    if(name === ""){
        setDataSearch({...dataSearch, [e.target.name]:e.target.value});
      }else{
        setDataSearch({...dataSearch, [value]:e[value], [name]:e});
      }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const getCatalogos = async e =>{
    setStatus({status: null, message: ""});
    setLoading(true);
    const request = await Get('catalogos-cursos');
    if(request.status === "success"){
      setInstructores(request.instructores);
      setAdministradores(request.administradores);
      setCursos(request.cursos);
      setClientes(request.clientes);
      
      setDataDetCurso({
        ...dataDetCurso,
        cursantes: request.cursantes
      })
    }else{
      setStatus(request);
    }
    setLoading(false);
  }

  const getDetCurso = async ()=>{
    const request = await Get(`programar-curso/${idCursoProgramado}`);
    if(request.status === "success"){
      setDataDetCurso(request.data);
      setShow(false);
      setIsEditing(true);
      return;
    }
    setStatus({status:request.status, message:"Error."});
  }

  const getCursantesCursos = async ()=>{
    setStatus({status: null, message: ""});

    const request = await Get(`cursante-programado/${idCursoProgramado}`);
    if(request.status === "success"){
        setCursantesProgramados(request.data);
        return;
    }

    setStatus({status:request.status, message:"Error."});
  }
  
  useEffect(()=>{
    if(idCursoProgramado){
        getDetCurso();
        getCursantesCursos();
    }
    getCatalogos();
  },[]);


   return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} ref={scrollRef}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <Programar 
                                setIsEditing = {setIsEditing} 
                                isEditing = {isEditing} 
                                dataDetCurso = {dataDetCurso} 
                                setShow={setShow}  
                                cursantesProgramados = {cursantesProgramados}
                                administradores = {administradores}
                                clientes = {clientes}
                                cursos = {cursos}
                                instructores= {instructores}
                                />
                        </Card>
                    </Grid>
                </Grid>
        </MDBox>
      <Divider />
      <Footer />
    </DashboardLayout>
  );
}

export default ProgramarContainer;
