
import React, { useState, useEffect, useRef } from "react";

import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Select from 'react-select';
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

import { useSearchParams, useNavigate } from "react-router-dom";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import {Get, GetGuest, Post, PostGuest} from "../../../tools/Helper";
import Message from "../../../tools/Message";
import TemplateEvaluacion from "./TemplateEvaluacion";

function EvaluacionDiganostica() {
  const scrollRef = useRef(React.createRef());
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const [dataCursante, setDataCursante] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answerQuestions, setAnswerQuestions] = useState([]);
  const [dataEvaluation, setDataEvaluation] = useState([]);
  const [dataComentario, setDataComentario] = useState(null);
  const [isResult, setIsResult] = useState(false);

  const [status, setStatus] = useState({status: null, message: ""});

  const navigate = useNavigate();
  const tokenUserExternal = searchParams.get("tk");
  const typeEvaluation = searchParams.get("type");

  const evaluationType={
    'diagnostica': 'Diagnóstica',
    'final': 'Final',
    'servicio': 'Servicio',
  }

  const organizeDataEvaluation = (eva) =>{
    const dataQuestions = [];
    eva.pregunta_evaluacion.forEach(item=>{
        const correct = item.respuesta_evaluacion.find(option=> parseInt(option.correcto, 10) === 1);
        const dataOptions = [];
        const dataOptionsValue = [];
        item.respuesta_evaluacion.forEach(option=>{
            dataOptions.push(option.descripcion);
            dataOptionsValue.push({
                option: option.descripcion,
                id_pregunta_evaluacion: option.id_pregunta_evaluacion,
                id_respuesta_evaluacion: option.id_respuesta_evaluacion,
                correct: option.correcto,
                status_respuesta: option.status_respuesta ? option.status_respuesta : null
            })
        });
        const dataQuestion={
            question: item.pregunta,
            id_pregunta_evaluacion: item.id_pregunta_evaluacion,
            options: dataOptions,
            optionsValue: dataOptionsValue,
            correctAnswer: correct.descripcion
        }
        dataQuestions.push(dataQuestion)
    })
    setQuestions(dataQuestions)
}

  const getEvaluacion = async (email = null, numOportunidades = 1) =>{
    setStatus({status: null, message: ""});
    setLoading(true);
    setIsResult(false);
    
    let request = '';
    
    const newData = {
      email,
      num_oportunidades: numOportunidades
    }

    if(typeEvaluation === 'servicio'){
      request = await PostGuest(`evaluacion-encuesta/${tokenUserExternal}/${typeEvaluation}`);
    }else{
      request = await PostGuest(`evaluacion-questions/${tokenUserExternal}/${typeEvaluation}`, newData);  
    }

    if(request.status === "success"){
        organizeDataEvaluation(request.data);
        setDataEvaluation(request.data);
        if(request.data.evaluado === 'si'){
          setIsResult(true);
          setDataEvaluation(request.data.encabezado);
          setLoading(false);
          return;
        }
    }
    if(request.status === "error"){
      setStatus({status:"error", message:"No tienes permiso."});
    }
    if(request.status === "completed"){
      setStatus({status:"error", message:"Este curso está cerrado."});
    }
    console.log(request.status)
    if(request.status === "no-evaluacion"){
      setStatus({status:"error", message:"No hay evaluaciones asignadas al curso."});
    }
    
    setLoading(false);

  }
  
  const validateUserExternal = () =>{
    const user = JSON.parse(localStorage.getItem('user'));

    if(tokenUserExternal){
      getEvaluacion();
      return;
    }

    if(!user){
      navigate("/login");
    }
  }

  const saveAnswerEvaluation = async() =>{
    if(Object.keys(dataCursante).length === 0){
      setStatus({status:"error", message:"El correo es obligatorio"});
      return;
    }
    
    let numAnswered = 0;
    const numQuestions = answerQuestions.length;
    answerQuestions.forEach(item=>{
      if(item.answer_response){
        numAnswered += 1 ;
      }
    })

    if((numAnswered < numQuestions ) || numAnswered === 0){
      setStatus({status:"error", message:"Todas las preguntas son obligatorias"});
      return;
    }

      setStatus({status: null, message: ""});
      setLoading(true);
      if(dataEvaluation.titulo === ''){
          setStatus({status: 'error', message: "Título requerido"});
          return;
      }

      const newData = {
          cursante: dataCursante,
          preguntas: answerQuestions,
          id_evaluacion: dataEvaluation.id_evaluacion,
          comentario: dataComentario,
          tipo: typeEvaluation
      }
  
      console.log(newData)
      const request = await Post(`respuesta-evaluacion`, newData);
      
    if(request.status === "success"){
      if(request.data.evaluado === 'si'){
        setIsResult(true);
        organizeDataEvaluation(request.data)
        setDataEvaluation({
          ...dataEvaluation,
          correctas: request.data.correctas,
          total: request.data.total
        })
      }
    }
      setAnswerQuestions([]);
      setStatus(request);
      setLoading(false);

  }

  useEffect(()=>{
    validateUserExternal();
  },[]);

   return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} ref={scrollRef}>
      <Fade in={show} timeout={800}>
      <Collapse in={show} timeout={50}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                  variant="gradient" bgColor="info"
                  borderRadius="lg" coloredShadow="info"
                >
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Evaluación {evaluationType[typeEvaluation]}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    <Message status={status} />
                    <Grid xs={12} md={12} spacing={1} p={5} >
                        <TemplateEvaluacion 
                          dataEvaluation={dataEvaluation} 
                          questions = {questions} 
                          setAnswerQuestions = {setAnswerQuestions}
                          setDataCursante = {setDataCursante}
                          dataCursante = {dataCursante}
                          isResult = {isResult}
                          typeEvaluation = {typeEvaluation}
                          tokenUserExternal = {tokenUserExternal}
                          getEvaluacion ={getEvaluacion}
                          setDataComentario = {setDataComentario}
                          loading = {loading}
                        />
                        <Grid item xs={12} md={12} mt={5} style={{ zIndex: 9 }}>
                            <Message status={status} />
                            {
                              ! isResult ?
                                <MDButton md={4} variant="gradient" color="info" style={{ color: 'white', borderRadius: 5 }}
                                    onClick = {()=>saveAnswerEvaluation()}
                                    fullWidth >
                                    Guardar Evaluación <Icon fontSize="medium">save</Icon>
                                </MDButton>
                              : null
                            }
                        </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        </Collapse>
        </Fade>
      </MDBox>
      <Divider />
      <Footer />
    </DashboardLayout>
  );
}

export default EvaluacionDiganostica;
