/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import ClienteFormExterno from "./ClienteFormExterno";
import {Get} from "../../tools/Helper";

function AddCliente() {

  const emptyForm = {
    uid: '',
    logo_cliente: '',
    nombre_comercial:'',
    razon_social: '',
    calle_num_inmueble: '',
    municipio_inmueble: '',
    colonia_inmueble: '',
    estado_inmueble: '',
    calle_num_fiscal: '',
    num_fiscal: '',
    cp_fiscal: '',
    municipio_fiscal: '',
    colonia_fiscal: '',
    estado_fiscal: '',
    persona_contacto: '',
    telefono: '',
    correo: '',
    status: 1,
    rfc: '',
    nombre_patron: '',
    nombre_representante: '',
    facturar: 0
  };

  const [show, setShow] = useState(false);
  const [isExternal, setIsExternal] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);


  const columns = [
    {
        name: "Nombre Comercial",
        selector: row => row.nombre_comercial,
    },
    {
        name: "Razon Social",
        selector: row => row.razon_social,
    },
    {
        name: "Persona de Contacto",
        selector: row => row.persona_contacto,
    },
    {
        name: "Telefono",
        selector: row => row.telefono,
    },
    {
        name: "Correo",
        selector: row => row.correo,
    }
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show}  timeout={50}>
            <ClienteFormExterno data={selectedData} isExternal = {isExternal} />
          </Collapse>
        </Fade>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddCliente;