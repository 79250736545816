/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Document, PDFDownloadLink, pdf } from '@react-pdf/renderer';  
import QRCode from 'qrcode';

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';

import domtoimage from 'dom-to-image';

import backImg from "../../tools/credencial_fondo.jpg";
import frame from "../../tools/qr.png"; 
import NoImg from "../../tools/no-img.png"; 

import canvasPreview from './canvasPreview'
import 'react-image-crop/dist/ReactCrop.css';
import useDebounceEffect from './useDebounceEffect'

import FondoCred from '../../tools/credencial_fondo.png';
import NewCertGenerator from "../../tools/NewCertGenerator";
import CertGeneratorPDF from "../../tools/CertGeneratorPDF";

import Servicios from '../tables/Servicios';

import {Get,Put,PostMulti} from "../../tools/Helper";
import Message from "../../tools/Message";

function EditCertificado({data, setShow, getLista, show, catalog}) {

  const [certData, setCertData] = useState([]);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  } 

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(9 / 12)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        const res = reader.result?.toString() || '';
        setImgSrc(res);
        setCertData({...certData, evidencias:{...certData.evidencias, foto: res, foto_nombre: e.target.files[0].name}});
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0,
        )
      }
    },
    100,
    [completedCrop, scale, 0],
  )

  const animatedComponents = makeAnimated();
  const scrollRef = useRef(React.createRef());

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;
  
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({status: {status:null, message: ""}});
  const [qr, setQr] = useState(null);

	const [clientes, setClientes] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [ocupaciones, setOcupaciones] = useState([]);

  useEffect(()=>{
  	const getData = async e =>{
    
	    setStatus({status: {status: null, message:""}, message: ""});
	    setLoading(true);

	    const request = await Get(`certificados/${data.id_certificado}`);

	    if(request.status === "success"){
	      // setClientes(request.clientes);
	      // setServicios(request.servicios);
	      // setResponsables(request.responsables);
        // setCatalogosJson(JSON.stringify(request));
        const temp = request.data;
        if(!temp.evidencias){
          temp.evidencias = {
            curp: '',
            curp_nombre: '',
            foto: '',
            foto_nombre: '',
            ine: '',
            ine_nombre: '',
          };
        }

        if(!temp.cursante){
          temp.cursante = {
            curp: "",
            puesto: "",
            id_ocupacion: "",
            ocupacion: {
              nombre: ""
            },
            nombre_completo: "",
          }
        }
        setCertData(temp);
	    }else{
	      setStatus({status:request.status, message:"Error."});
	    }

	    setLoading(false);
	  }

    getData();
  },[]);

  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
      setCertData({...certData, [e.target.name]:e.target.value});
    }else if(value === "id_instructor"){
      setCertData({...certData, [value]:e.id_firmante, [name]:e});
    }else if(value === "id_administrador"){
      setCertData({...certData, [value]:e.id_firmante, [name]:e});
    }else{
      setCertData({...certData, [value]:e[value], [name]:e});
    }
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const [dataFinal, setDataFinal] = useState(null);

  const submitOss = async() =>{
    const tempData = certData;
    setStatus({status: null, message: ""});
    setLoading(true);

    if(completedCrop){
      const type = tempData.evidencias.foto.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      tempData.evidencias.foto = await previewCanvasRef.current.toDataURL(type);
    }

    tempData.nombre_cursante = tempData.cursante.nombre_completo;
    tempData.curp_cursante = tempData.cursante.curp;
    tempData.puesto = tempData.cursante.puesto;
    tempData.id_ocupacion = tempData.cursante.id_ocupacion;
    const request = await Put(`certificados/${certData.id_certificado}`,tempData);

    if(request.status.status === "success"){
      console.log("ya");

      await setDataFinal(request.data);
      const qrData = await QRCode.toDataURL(`https://certificados.paymun.mx/${request.data.folio_unico_registro}/${request.data.token_certificado}`);
      await setQr(qrData);  
      
      const doc = 
      <Document>
        <CertGeneratorPDF data={request.data} qr={qrData} />
        <NewCertGenerator data={request.data} qr={qrData} />
      </Document>;

      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();

      const credencial = await domtoimage.toBlob(document.getElementById('canv2'));

      console.log("ya");
      const formData2 = new FormData();
      formData2.append("certificado", blob);
      formData2.append("credencial", credencial);

      formData2.append("id_certificado", request.data.id_certificado);

      const request2 = await PostMulti('email-certificado',formData2) ;
  
      setScale(0.9);
      setCompletedCrop(undefined);
      setCrop(undefined);

      setCompletedCrop();
      await getLista();
      setStatus(request);
    }

    if(scrollRef.current){
        scrollRef.current.scrollIntoView({ 
             behavior: "smooth", 
          });
      }

    setLoading(false);
  }

  const getCurso = catalog.cursos.find(option => option.id_curso === parseInt(certData.id_curso,10));
  const getCliente = catalog.clientes.find(option => option.id_cliente === parseInt(certData.id_cliente,10));
  const getInstructor = catalog.instructores.find(option => option.id_firmante === parseInt(certData.id_instructor,10));
  const getAdministrador = catalog.administradores.find(option => option.id_firmante === parseInt(certData.id_administrador,10));
  const getOcupacion = catalog.ocupaciones.find(option => option.id_ocupacion === certData.id_ocupacion);
  const getCursante = catalog.cursantes.find(option => parseInt(option.id_cursante,10) === parseInt(certData.id_cursante,10));

  const handleFile = (event, name) => {

    const extension = event.target.files[0].type;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
        setCertData({...certData, evidencias:{...certData.evidencias, [name]: reader.result, [`${name}_nombre`]: event.target.files[0].name}});
    };

  }

  const borrarEvidencia = (name) => {
    setCertData({...certData, evidencias:{...certData.evidencias, [name]: '', [`${name}_nombre`]: ''}});
    if(name === "foto"){
      setCompletedCrop(undefined);
    }
  }

  const descargarEvidencia = (name) =>{
    const a = document.createElement("a"); // Create <a>
    a.href = certData.evidencias[name]; // Image Base64 Goes here
    a.download = certData.evidencias[`${name}_nombre`]; // File name Here
    a.click();
  }

	return (
        <MDBox pb={3} ref={scrollRef}>
          {!loading ? <Card>
            <MDBox mx={2} mt={-3} py={3} px={2}
              variant="gradient" bgColor="info"
              borderRadius="lg" coloredShadow="info"
            >
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <MDTypography variant="h4" display="inline" mr="auto" color="white">
                  Editar certificado
                </MDTypography>
              </Grid>
              <Grid item xs={6} textAlign="right" >
                <MDButton variant="gradient" color="light" 
                  onClick={()=>setShow(true)}>
                  <Icon fontSize="small">arrow_back</Icon>
                    &nbsp;Regresar a la lista
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
            <MDBox pt={3}>
              <MDBox pt={3}>
              <MDBox pb={3} px={3}>
                <MDBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Message status={status.status} />
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5">
                          Participante
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} mt="auto" className="z-101">
                        <MDTypography variant="caption" pt={3} pr={3}>Nombre</MDTypography>
                        <Select className={`z-101 ${verifyError("id_cursante") ? "border-red" : ""}`}
                          options={catalog.cursantes} placeholder="Cliente"
                          value={getCursante} onChange={(e) => handleChange(e,"cursante","id_cursante")}
                          getOptionValue={(option) => `${option.id_cursante}`}
                          getOptionLabel={(option) => `${option.nombre_completo}`}
                        />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="text" label="CURP" name="curp_cursante"
                          error={verifyError("curp_cursante")} errorMsg={getError("curp_cursante")}
                          value={certData.cursante.curp} onChange={handleChange} fullWidth disabled />
                      </Grid>
                      <Grid item xs={4} mt="auto" className="z-9">
                        <MDInput type="text" label="Ocupación" name="ocupacion"
                          error={verifyError("ocupacion")} errorMsg={getError("ocupacion")}
                          value={certData.cursante.ocupacion.nombre} onChange={handleChange} fullWidth disabled />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="text" label="Puesto" name="puesto"
                          error={verifyError("puesto")} errorMsg={getError("puesto")}
                          value={certData.cursante.puesto} onChange={handleChange} fullWidth disabled />
                      </Grid>
                      <Grid item xs={12}>
                        <MDTypography variant="h5">
                          Datos del curso
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} className="z-8">
                        <Select className={`z-10 ${verifyError("id_curso") ? "border-red" : ""}`}
                          options={catalog.cursos} placeholder="Seleccione un curso"
                          value={getCurso} onChange={(e) => handleChange(e,"curso","id_curso")}
                          getOptionValue={(option) => `${option.id_curso}`}
                          getOptionLabel={(option) => `${option.nombre}`}
                        />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="text" label="Duracion (Horas)" name="duracion_curso"
                          error={verifyError("duracion_curso")} errorMsg={getError("duracion_curso")}
                          value={certData.duracion_curso} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="text" label="Lugar" name="lugar"
                          error={verifyError("lugar")} errorMsg={getError("lugar")}
                          value={certData.lugar} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="date" label="Fecha inicio" name="fecha_imparticion_curso"
                          error={verifyError("fecha_imparticion_curso")} errorMsg={getError("fecha_imparticion_curso")}
                          value={certData.fecha_imparticion_curso} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="date" label="Fecha término" name="fecha_termino"
                          error={verifyError("fecha_termino")} errorMsg={getError("fecha_termino")}
                          value={certData.fecha_termino} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="date" label="Fecha expiración" name="vigencia_fecha"
                          error={verifyError("vigencia_fecha")} errorMsg={getError("vigencia_fecha")}
                          value={certData.vigencia_fecha} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={4} mt={-1} className="z-7">
                        <MDTypography variant="caption" pt={3} pr={3}>Instructor</MDTypography>
                        <Select className={`z-10 ${verifyError("id_instructor") ? "border-red" : ""}`}
                          options={catalog.instructores} placeholder="Instructor"
                          value={getInstructor} onChange={(e) => handleChange(e,"instructor","id_instructor")}
                          getOptionValue={(option) => `${option.id_firmante}`}
                          getOptionLabel={(option) => `${option.nombre_completo}`}
                        />
                      </Grid>
                      <Grid item xs={4} mt={-1} className="z-7">
                        <MDTypography variant="caption" pt={3} pr={3}>Administrador</MDTypography>
                        <Select className={`z-10 ${verifyError("id_administrador") ? "border-red" : ""}`}
                          options={catalog.administradores} placeholder="Administrador"
                          value={getAdministrador} onChange={(e) => handleChange(e,"administrador","id_administrador")}
                          getOptionValue={(option) => `${option.id_firmante}`}
                          getOptionLabel={(option) => `${option.nombre_completo}`}
                        />
                      </Grid>
                    </Grid>
                    
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" 
                      onClick={() => submitOss()} fullWidth>
                      Editar y enviar documentos de acreditación
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            </MDBox>
          </Card> : <Card><MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox></Card>}

            <div className="cont cont-hide">
                { dataFinal && qr ? <div className="pos-rel border1" id="canv2">
                    <img
                      alt="bg"
                      src={backImg}
                      className="pos-rel"
                    />
                    <img
                      alt="foto"
                      src={dataFinal.cursante.evidencias && dataFinal.cursante.evidencias.foto ? dataFinal.cursante.evidencias.foto : NoImg}
                      className="avatar"
                    />
                    <img src={qr} alt="qr" className="qr" />
                    <div className="nombre-acreditado">
                      Nombre del acreditado
                      <br />
                      <span className="bold">{ dataFinal.nombre_cursante }</span>
                    </div>
                    <div className="curso">
                      Curso:
                      <br />
                      <span className="bold">{ dataFinal.curso.nombre }</span>
                    </div>
                    <div className="fecha-finalizacion">
                      Fecha de finalización:
                      <br />
                      <span className="bold">{ dataFinal.fecha_termino }</span>
                    </div>
                    <div className="no-certificacion">
                      No. de certificación:
                      <br />
                      <span className="bold">{ dataFinal.folio_unico_registro }</span>
                    </div>
                    <div className="desarrollo">Desarrollo de Talento</div>
                    <div className="humano">Humano</div>
                  </div> : "Cargando..."}
              </div>
        </MDBox>
	);
}

EditCertificado.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  catalog: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default EditCertificado;