/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';
import Select from 'react-select';

import imageCompression from 'browser-image-compression';
import LocationTracker from "tools/LocationTracker";
import {Post,Put,PostGuest, Get} from "../../tools/Helper";
import Message from "../../tools/Message";

function ClienteForm({data, editar, setShow, getLista, isExternal = false, listRegime}) {

	const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));

  const getData = async (dataCliente) => {
        
    if (data.id_cliente === undefined)
      return;

    setLoading(true);
    setStatus({ status: null, message: "" });
        
    const request = await Get(`cliente-externo-by-id/${data.id_cliente}`);
  
    if (request.status === "success") {  
      setFormData(request.data[0]);    
    } else {
      setStatus({ status: request.status, message: "Error." });
    }
  
    setLoading(false);
  };

  
  useEffect(()=>{
   
    getData(data);       
    setStatus({status: null, message: ""});
  
  },[data,setFormData]);  

	const handleChange = (e) =>{
	    setFormData({...formData, [e.target.name]:e.target.value});
	}

  const handleChangeSelect = (e, name = "", value = "") =>{
    setFormData({
      ...formData, 
        [value]:e[name]
    });
  }

  const handleCheck = (e) =>{
    setFormData({...formData, facturar:e.target.checked ? 1 : 0});
  }

  const handleFile = async (event) => {
    setStatus({
      status: 'warning',
      message: "Comprimiendo imagen...",
    });
    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      console.log(compressedFile);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile); 
      reader.onloadend = function() {
        const imgBase64data = reader.result;                
        setFormData({
          ...formData,
          logo_cliente: imgBase64data,
        });
        setStatus({
          status: null,
          message: "",
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  const deleteLogo = () =>{
    setFormData({
      ...formData,
      logo_cliente: '',
    });
  }

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const tempData = formData;
    let request;
    if(editar){
      request =  await Put(`clientes/${formData.id_cliente}`,formData) 
    }else if(userData){
      request = await Post('clientes',formData)
    }else{
      request = await PostGuest('cliente-invitado',formData);
    }

    if(request.status === "success"){
      if(userData) { await getLista() }
      if(editar){
        setFormData(tempData);
      }
      setStatus(request);
      setLoading(false);
      return;
    }

    setStatus({ 
      ...request, 
      status: 'error'
    });
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }
  const getRegime = listRegime.find(option => option.key === formData.regimen_fiscal);
	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    {editar ? "Actualizar" : "Agregar"} Cliente
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  {
                    !isExternal ?
                      <MDButton variant="gradient" color="light" 
                        onClick={()=>setShow(true)}>
                        <Icon fontSize="small">arrow_back</Icon>
                          &nbsp;Regresar a la lista
                      </MDButton>
                    : null
                  }
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : 
                <>
                <Grid item xs={4} mt="auto">
                  {
                    formData.logo_cliente ?
                    <div>
                      <img alt="logo" width={150} src={formData.logo_cliente} />
                      <MDButton variant="gradient" color="error" disabled={loading}
                        onClick={()=>deleteLogo()} fullWidth>
                        Quitar Logo
                      </MDButton>
                    </div>
                    :
                  <div id="src-logo" className='file-select'>
                    <input type='file' accept="image/*" 
                      onChange={event => handleFile(event)}
                    />
                  </div>
                  }
                </Grid>
                <Grid item xs={8} mt="auto" style={{ display: 'flex', justifyContent: 'center' }} >
                  <MDInput type="text" label="Razón Social" name="razon_social" disabled={loading}
                      error={verifyError("razon_social")} errorMsg={getError("razon_social")}
                      value={formData.razon_social} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Nombre Comercial" name="nombre_comercial" disabled={loading}
                      error={verifyError("nombre_comercial")} errorMsg={getError("nombre_comercial")}
                      value={formData.nombre_comercial} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="RFC" name="rfc" disabled={loading}
                      error={verifyError("rfc")} errorMsg={getError("rfc")}
                      value={formData.rfc} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Persona de Contacto" name="persona_contacto" disabled={loading}
                      error={verifyError("persona_contacto")} errorMsg={getError("persona_contacto")}
                      value={formData.persona_contacto} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={12} mt="auto">
                      Dirección del inmueble:
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Calle y número" name="calle_num_inmueble" disabled={loading}
                      error={verifyError("calle_num_inmueble")} errorMsg={getError("calle_num_inmueble")}
                      value={formData.calle_num_inmueble} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Municipio/Alcaldía" name="municipio_inmueble" disabled={loading}
                      error={verifyError("municipio_inmueble")} errorMsg={getError("municipio_inmueble")}
                      value={formData.municipio_inmueble} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Colonia" name="colonia_inmueble" disabled={loading}
                      error={verifyError("colonia_inmueble")} errorMsg={getError("colonia_inmueble")}
                      value={formData.colonia_inmueble} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Estado" name="estado_inmueble" disabled={loading}
                      error={verifyError("estado_inmueble")} errorMsg={getError("estado_inmueble")}
                      value={formData.estado_inmueble} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={12} mt="auto">
                      Domicilio fiscal:
                </Grid>
                <Grid item xs={12} mt="auto">
                  <label className="form-check-label" htmlFor="flexCheckDefault">¿Requiere factura?
                    <input style={{marginLeft: 5}} className="form-check-input" type="checkbox" name="check" checked={formData.facturar === 1 ?? false} onChange={handleCheck} id="flexCheckDefault" />
                  </label>
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Calle y número" name="calle_num_fiscal" disabled={loading}
                      error={verifyError("calle_num_fiscal")} errorMsg={getError("calle_num_fiscal")}
                      value={formData.calle_num_fiscal} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Número" name="num_fiscal" disabled={loading}
                      error={verifyError("num_fiscal")} errorMsg={getError("num_fiscal")}
                      value={formData.num_fiscal} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Municipio/Alcaldía" name="municipio_fiscal" disabled={loading}
                      error={verifyError("municipio_fiscal")} errorMsg={getError("municipio_fiscal")}
                      value={formData.municipio_fiscal} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Colonia" name="colonia_fiscal" disabled={loading}
                      error={verifyError("colonia_fiscal")} errorMsg={getError("colonia_fiscal")}
                      value={formData.colonia_fiscal} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Código postal" name="cp_fiscal" disabled={loading}
                      error={verifyError("cp_fiscal")} errorMsg={getError("cp_fiscal")}
                      value={formData.cp_fiscal} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Estado" name="estado_fiscal" disabled={loading}
                      error={verifyError("estado_fiscal")} errorMsg={getError("estado_fiscal")}
                      value={formData.estado_fiscal} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <Select 
                      className={`z-10 ${verifyError("regimen_fiscal") ? "border-red" : ""}`}                      
                      options={listRegime} placeholder="Régimen Fiscal"
                      value={getRegime || ''} onChange={(e) => handleChangeSelect(e,"key","regimen_fiscal")}
                      getOptionValue={(option) => `${option.key}`}
                      getOptionLabel={(option) => `${option.name}`}
                      />
                </Grid>
                <Grid item xs={12} mt="auto">
                      Datos de contacto:
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Telefono" name="telefono" disabled={loading}
                      error={verifyError("telefono")} errorMsg={getError("telefono")}
                      value={formData.telefono} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Correo" name="correo" disabled={loading}
                      error={verifyError("correo")} errorMsg={getError("correo")}
                      value={formData.correo} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Representante legal" name="nombre_patron" disabled={loading}
                      error={verifyError("nombre_patron")} errorMsg={getError("nombre_patron")}
                      value={formData.nombre_patron} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Representante de los trabajadores" name="nombre_representante" disabled={loading}
                      error={verifyError("nombre_representante")} errorMsg={getError("nombre_representante")}
                      value={formData.nombre_representante} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <LocationTracker formData = {formData} setFormData = {setFormData} />
                </Grid>
                <Grid item xs={12} mt="auto">
                  <MDButton variant="gradient" color="info" disabled={loading}
                      onClick={()=>doRequest()} fullWidth>
                      {editar ? "Actualizar" : "Agregar"}
                  </MDButton>
                </Grid></>}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
	);
}

ClienteForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  isExternal: PropTypes.bool.isRequired,
  listRegime: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default ClienteForm;