import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

import Message from "../../tools/Message"
import {PostGuest} from "../../tools/Helper";

function BoxLinksRegister ({ titleTop, type, colorButton }) {
    const [domain, setDomain] = useState('');
    const [userLogin, setUserLogin] = useState({});
    const [statusCopy, setStatusCopy] = useState({status: null, message: ""});
    const [linkCopied, setLinkCopied] = useState('');
    const [isShowSendEmail, setIsShowSendEmail] = useState(false);
    const [status, setStatus] = useState({status: null, message: ""});
    const [formData, setFormData] = useState({
      email_send: ''
    })

    useEffect(()=>{
        const fullpath = window.location.href;
        const regex = window.location.pathname;
        const domainName= fullpath.replace(regex, '');
        const {user} = JSON.parse(localStorage.getItem('user'));
        setUserLogin(user);
        setDomain(domainName);
      },[]);

      const copyLink = (action) =>{
        setStatusCopy({status: null, message: ""});
        let link = `${domain}/cliente-invitado?tk=emxzubjsb1ojnt8`;

        if(type === 'independiente'){
          link = `${domain}/cursante-invitado?tk=txapyv6s7nko0pf`;
        }

        if(action==='copy'){
          navigator.clipboard.writeText(link);
          setStatusCopy({status: 'success', message: 'Enlace copiado en portapapeles...'});
          setTimeout(()=>{
            setStatusCopy({status: null, message: ""});
          },2000);
          return;
        }
        setLinkCopied(link);
        setIsShowSendEmail(!isShowSendEmail);
      }

      const doRequest = async e =>{
        const data = {
          email: formData.email_send,
          link: linkCopied
        }
        setStatusCopy({status: "warning", message: "Enviando email..."});
        const request = await PostGuest('send-mail-customer',data) ;
        setStatusCopy(request);

        if(request.errors){
          setStatusCopy({ status: "error", message: request.errors.email[0]})
        }

        setTimeout(()=>{
          setStatusCopy({status: null, message: ""});
        },2000);
        setIsShowSendEmail(false);
      }
      
      const sendMailLink = () =>{
        setStatusCopy({status: null, message: ""});
        console.log('enviando....')
        console.log(linkCopied);
        if(formData.email_send === ''){
          setStatusCopy({status: "error", message: "Escriba un email!"});
          return;
        }
        setStatusCopy({status: "warning", message: "Enviando email..."});
        doRequest();
      }
      
      const handleChange = (e, name = "", value = "") =>{
        if(name === ""){
          setFormData({...formData, [e.target.name]:e.target.value});
        }else{
          setFormData({...formData, [value]:e.value, [name]:e});
        }
    
        if("errors" in statusCopy){
          const verifyName = name === "" ? e.target.name : name;
          if(verifyName in statusCopy.errors){
            const temp = statusCopy;
            delete temp.errors[verifyName];
            setStatusCopy(temp);
          }
        }
      }

      const verifyError = (name) =>{
        if("errors" in statusCopy){
          if(name in statusCopy.errors){
            return true;
          }
        }
    
        return false;
      }
    
      const getError = (name) =>{
        if("errors" in statusCopy){
          if(name in statusCopy.errors){
            return statusCopy.errors[name][0];
          }
        }
    
        return "";
      }

    return(
      <Grid item md={3} textAlign="center" className="shareLink"  style={{ paddingLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
        <p style={{ fontSize: 20, fontWeight: 'bold', color: 'white'}}>{ titleTop }</p>
        <Grid md={12}>
          <Grid md={6} style={{ float: 'left', marginRight: 5 }}>
            <MDButton variant="gradient" color={colorButton} 
              onClick={()=>copyLink('send')}>
              <Icon fontSize="small">mail_outline</Icon>
              &nbsp;Enviar
            </MDButton>
          </Grid>
          <Grid md={6} style={{ float: 'left' }}>
            <MDButton variant="gradient" color={colorButton} 
              onClick={()=>copyLink('copy')}>
              <Icon fontSize="small">content_copy</Icon>
              &nbsp;Copiar
            </MDButton>
          </Grid>
        </Grid>
        <Fade in={isShowSendEmail} timeout={800}>
            <Grid item md={12} mt="auto" style={{marginTop: 4, display: isShowSendEmail ? 'flex' : 'none' }} >
              <Grid item md={9}>
              < MDInput type="email" label="Escriba un email" name="email_send"
                  style={{ backgroundColor: 'white', borderRadius: 5 }}
                  error={verifyError("email_send")} errorMsg={getError("email_send")}
                  value={formData.email_send} onChange={event => handleChange(event)} fullWidth />
              </Grid>
              <Grid item md={3}>
                <MDButton variant="gradient" color="light" 
                  style = {{ marginLeft: 5 }}
                  onClick={()=>sendMailLink()}>
                  <Icon fontSize="small">send</Icon>
                  
                </MDButton>
              </Grid>
            </Grid>
        </Fade>
        {
            statusCopy.status !== null ?
              <MDAlert color={statusCopy.status}>
                {statusCopy.message}
              </MDAlert>
            : null
          }
    </Grid>
    )
}

BoxLinksRegister.propTypes = {
  titleTop: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  colorButton: PropTypes.string.isRequired
}

export default BoxLinksRegister;