/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Select from 'react-select';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import {Post,Put, Del} from "../../tools/Helper";
import Message from "../../tools/Message";

function ServicioForm({editar, data, dataEvaluaciones, setShow, getLista}) {

  const cred = [
    {
      'value': "0",
      'text': 'No'
    },
    {
      'value': "1",
      'text': 'Si'
    }
  ];

	const [formData, setFormData] = useState(data);
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({status: null, message: ""});

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

	useEffect(()=>{
	    setFormData(data);
	    setStatus({status: null, message: ""});
  	},[data,setFormData]);

  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
      setFormData({...formData, [e.target.name]:e.target.value});
    }else{
      setFormData({...formData, [value]:e.value, [name]:e});
    }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);
    const tempData = formData;
    const request = editar ? await Put(`cursos/${formData.id_curso}`,formData) : await Post('cursos',formData) ;

    if(request.status === "success"){
      await getLista();
      if(editar){
        setFormData(tempData);
      }
    }

    setStatus(request);
    setLoading(false);
  }

  const deletePregunta = async e =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`cursos/${formData.id_curso}`);

    if(request.status === "success"){
      await getLista();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const getCred = cred.find(option => parseInt(option.value,10) === parseInt(formData.cred,10));
  let getEvaluacion = 0;
  if(formData.evaluacion_curso){
    getEvaluacion = dataEvaluaciones.find(option => parseInt(option.id_evaluacion,10) === parseInt(formData.evaluacion_curso.id_evaluacion,10));
  }
  


	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    {editar ? "Actualizar" : "Agregar"} Curso
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  <MDButton variant="gradient" color="light" 
                    onClick={()=>setShow(true)}>
                    <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar a la lista
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : <>
                    <Grid item xs={4} mt="auto">
                      <MDInput type="text" label="Nombre del curso" name="nombre"
                          error={verifyError("nombre")} errorMsg={getError("nombre")}
                          value={formData.nombre} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={4} mt="auto">
                      <MDInput type="text" label="Área temática" name="area"
                          error={verifyError("area")} errorMsg={getError("area")}
                          value={formData.area} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={4} mt="auto">
                      <MDInput type="text" label="Duración (Horas)" name="duracion_horas"
                          error={verifyError("duracion_horas")} errorMsg={getError("duracion_horas")}
                          value={formData.duracion_horas} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={4} mt="auto">
                      <MDInput type="date" label="Fecha registro STPS" name="fecha_registro"
                          error={verifyError("fecha_registro")} errorMsg={getError("fecha_registro")}
                          value={formData.fecha_registro} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} lg={4} mt="auto">
                      ¿Generar credenciales?
                      <Select className={`z-10 ${verifyError("cred") ? "border-red" : ""}`}
                        options={cred} placeholder="¿Generar credenciales?"
                        value={getCred} onChange={(e) => handleChange(e,"credArr", "cred")}
                        getOptionValue={(option) => `${option.value}`}
                        getOptionLabel={(option) => `${option.text}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} mt="auto">
                      <Select className={`z-102 ${verifyError("evaluacion") ? "border-red" : ""}`}
                          options={dataEvaluaciones} placeholder="Evaluacion"
                          value={getEvaluacion} onChange={(e) => handleChange(e,"evaluacion_curso","id_evaluacion_curso")}
                          getOptionValue={(option) => `${option.id_evaluacion}`}
                          getOptionLabel={(option) => `${option.titulo}`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                      <MDButton variant="gradient" color="info" disabled={loading}
                        onClick={()=>doRequest()} fullWidth>
                        {editar ? "Actualizar" : "Agregar"}
                      </MDButton>
                    </Grid>
                    {editar ? 
                    <Grid item xs={6}>
                      <MDButton variant="gradient" color="warning" disabled={loading}
                        onClick={()=>openModal()} fullWidth>
                        Eliminar
                      </MDButton>
                    </Grid> : ""}
                    </>}
              </Grid>
            </MDBox>
          </Card>
          <Modal
            isOpen={modalIsOpen}
            contentLabel="¿Seguro que desea eliminar el curso?"
            style={customStyles}
          >
            <Grid container spacing={2} p={4}>
              <h2>¿Seguro que desea eliminar el curso?</h2>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="info" disabled={loading}
                  onClick={()=>closeModal()} fullWidth>
                  Cancelar
                </MDButton>
              </Grid>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="warning" disabled={loading}
                  onClick={()=>deletePregunta()} fullWidth>
                  Eliminar
                </MDButton>
              </Grid>
            </Grid>
          </Modal>
        </MDBox>
	);
}

ServicioForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  dataEvaluaciones: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default ServicioForm;