import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPDF , { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image  } from '@react-pdf/renderer';

import { Divider } from '@mui/material';
import Logo from './logo.png';
import Logo2 from './logo2.jpg';
import { getDateLarge } from './utils';



function PDFGeneratorInformeFinal({dataReporte, imgChart, imgChartEncuesta, dataComentarios}) {

	const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 9,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
        display:"flex",
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    table:{
      width:"100%",
      height:"88%",
      display:"flex",
      borderColor:"#000000",
      
      marginLeft:"auto",
      marginRight:"auto",
      marginTop:"auto",
      marginBottom:35,
      fontSize: 9
    },

    row:{
      width: "100%",
      borderColor:"#000000",
      flexDirection: 'row',
    },

    flexY:{
      flex: "1 1 auto",
    },

    col:{
      borderColor:"#000000",
      flex:1, 
      paddingTop:0,
    },

    flex5:{
      flex:4,
    },

    borderX:{
      borderRight:1,
      borderLeft:1,
    },

    borderY:{
      borderTop:1,
      borderBottom:1,
    },

    borderS:{
      borderLeft:1,
    },

    borderE:{
      borderRight:1,
    },

    borderT:{
      borderTop:1,
    },

    borderB:{
      borderBottom:1,
    },

    center:{
      verticalAlign:"middle",
      marginTop:"auto",
      marginBottom:"auto",
      paddingLeft:15,
      paddingRight:15,
      paddingTop: 3,
      paddingBottom: 3,
    },

    textCenter:{
      textAlign:"center",
    },

    py10:{
      paddingTop:30,
      paddingBottom:30,
    },

    topSection:{
      marginTop: 30
    },

    flex15:{
      flex:1.5,
    },

    flex2:{
      flex:2,
    },

    head:{
      fontWeight:"heavy",
      backgroundColor:"#dedede",
      fontFamily: 'Helvetica-Bold'
    },

    img:{
      display:"block",
      position:"absolute",
      width:"15%",
      left:50,
      top: 25,
    },

    img2:{
      display:"block",
      position:"absolute",
      height:"50",
      right:50,
      top: 25,
    },

    imgRecomend: {
      width: '90%',
    },

    red:{
      color:"#ff0004"
    },

    textSmall:{
      fontSize: 8,
    },

    alend:{
      textAlign:"right"
    },

    pagina:{
      position:"fixed",
      bottom:15,
      left:0,
      paddingRight:28,
      textAlign:"right",
      fontSize:7,
    },

    chartContainer: {
      width: '100%',
      height: 150,
      marginBottom: 20,
    },

  });

  const Contingencias = () => dataReporte.contingencia_ajuste ? dataReporte.contingencia_ajuste.map( item => 
    <View style={[styles.row, styles.borderB]}>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter]}>{item.contingencia}</Text>
      </View>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter]}>{item.solucion}</Text>
      </View>
    </View>
  ) : "";

  const CalificacionesParticipantes = () => dataReporte.cursante_programado ? dataReporte.cursante_programado.map( (item, index) => 
    <View style={[styles.row, styles.borderB]}>
      <View style={[styles.col, styles.borderE, {flex: 0.3}]}>
        <Text style={[styles.center, styles.textCenter, styles.textSmall]}>{index+1}</Text>
      </View>
      <View style={[styles.col, styles.borderE, styles.flex2]}>
        <Text style={[styles.center, styles.textCenter, styles.textSmall]}>{item.cursante.nombre_completo}</Text>
      </View>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmall]}>{item.calificacion_diagnostica}</Text>
      </View>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmall]}>{item.calificacion_final}</Text>
      </View>
    </View>
  ) : "";

  const AsistenciaParticipantes = () => dataReporte.cursante_programado ? dataReporte.cursante_programado.map( item => 
    <View style={[styles.row, styles.borderB]}>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmall]}>{item.cursante.nombre_completo}</Text>
      </View>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmall]}>Si</Text>
      </View>
    </View>
  ) : "";

  const ComentariosEncuesta = () => dataComentarios ? dataComentarios.map( item => 
        <Text style={[styles.center]}>{item.comentario}</Text>
  ) : "";

  let numRecomenda = 0;
  const FotosRecomendaciones = (brigadistas) => 
  brigadistas.map((item) =>{
    numRecomenda += 1;
    return(
      <View style={[styles.row, styles.borderB]}>
        <View style={[styles.col, {flex: 0.3}]}>
          <Text style={[styles.center]}>{numRecomenda}</Text>
        </View>
        <View style={[styles.col, styles.flex2]}>
            <Image src={item.foto} style={styles.imgRecomend}/>
        </View>
        <View style={[styles.col, styles.flex2]}>
          <Text style={[styles.center, styles.textSmall]}>{item.comentario}</Text>
        </View>
        <View style={[styles.col]}>
          <Text style={[styles.center, styles.textSmall]}>{item.referencia}</Text>
        </View>
    </View>
    )
    }
);

const fotosRecomendacionesFilter = dataReporte.fotografia_curso.filter(item=> item.comentario !== null);
const fotosMemoriaFilter = dataReporte.fotografia_curso.filter(item=> item.comentario === null);

const FotosMemoria = () => fotosMemoriaFilter ? fotosMemoriaFilter.map( (item, index) => {
  if (index % 2 === 0) {
    return (
      <View style={[styles.row, styles.borderB, {padding: 2}]} key={item.id_fotografia_curso}>
        <View style={[styles.col, { justifyContent: 'center', alignItems: 'center' }]}>
          <Image src={item.foto} style={{ width: '80%', height: 'auto' }} />
        </View>
        {fotosMemoriaFilter[index + 1] && (
          <View style={[styles.col, { justifyContent: 'center', alignItems: 'center' }]}>
            <Image
              src={fotosMemoriaFilter[index + 1].foto}
              style={{ width: '80%', height: 'auto' }}
            />
          </View>
        )}
      </View>
    );
  }
  return null
  }
) : "";

  const size = 5; const arrayOfArrays = [];
  for (let i=0; i<fotosRecomendacionesFilter.length; i+=size) {
      arrayOfArrays.push(fotosRecomendacionesFilter.slice(i,i+size));
  }

  function convertHtmlToString(html) {
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || '';
  }

	return (
    <Document>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        
        <Image src={Logo} style={styles.img}/>

        {
          dataReporte.cliente.logo_cliente != null ?
            <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          : 
            null
        }
      
        <View style={styles.table}>
          <View style={[styles.borderX, styles.borderT]}>
            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>INFORME FINAL DEL CURSO </Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCION 1: INFORME GENERAL</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center]}>Nombre del instructor:</Text>
              </View>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{dataReporte.instructor}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center]}>Nombre del curso:</Text>
              </View>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{dataReporte.curso.nombre}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center]}>Nombre del cliente:</Text>
              </View>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{dataReporte.cliente.razon_social}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center]}>Fecha de impartición del curso:</Text>
              </View>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{getDateLarge(dataReporte.fecha_inicio)}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center]}>Lugar de impartición:</Text>
              </View>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{dataReporte.cliente.calle_num_inmueble}, {dataReporte.cliente.colonia_inmueble}, {dataReporte.cliente.municipio_inmueble}, {dataReporte.cliente.estado_inmueble}</Text>
              </View>
            </View>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 2: COMENTARIOS ACERCA DEL PROCESO DE APRENDIZAJE Y DEL GRUPO</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{dataReporte.seccion2_comentarios}</Text>
              </View>
            </View>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 3: NIVEL DE CUMPLIMIENTO DE LOS OBJETIVOS/RESULTADOS DE APRENDIZAJE Y DE LAS EXPECTATIVAS DEL CURSO</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{dataReporte.seccion3_cumplimiento}</Text>
              </View>
            </View>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 4: PLAN DE SEGUIMIENTO A LOS PARTICIPANTES EN LA APLICACIÓN DE LO APRENDIDO</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>¿Qué se desea conseguir?</Text>
              </View>
              <View style={[styles.col, styles.borderE]}>
                <Text style={[styles.center,styles.textCenter]}>{dataReporte.que_desea}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>¿Cómo se desea conseguir?</Text>
              </View>
              <View style={[styles.col, styles.borderE]}>
                <Text style={[styles.center,styles.textCenter]}>{dataReporte.como_desea}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>¿Cuándo se desea conseguir?</Text>
              </View>
              <View style={[styles.col, styles.borderE]}>
                <Text style={[styles.center,styles.textCenter]}>{dataReporte.cuando_desea}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>¿Dónde se desea conseguir?</Text>
              </View>
              <View style={[styles.col, styles.borderE]}>
                <Text style={[styles.center,styles.textCenter]}>{dataReporte.donde_desea}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>¿Quién debe ayudar a conseguirlo?</Text>
              </View>
              <View style={[styles.col, styles.borderE]}>
                <Text style={[styles.center,styles.textCenter]}>{dataReporte.quien_debe}</Text>
              </View>
            </View>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 5: CONTINGENCIAS/AJUSTES AL PLAN DE SESIÓN QUE SE PRESENTARON Y SU RESOLUCIÓN</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>Contingencia/ajuste</Text>
              </View>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>Solución</Text>
              </View>
            </View>
            <Contingencias />

          </View>
        </View>
      </Page>

      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        
        <Image src={Logo} style={styles.img}/>

        {
          dataReporte.cliente.logo_cliente != null ?
            <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          : 
            null
        }

        <View style={styles.table}>
          <View style={[styles.borderX, styles.borderT]}>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 6: RECOMENDACIONES DE PARTICIPANTES EN LA ENCUESTA DE SATISFACCIÓN PARA LA MEJORA DEL CURSO</Text>
              </View>
            </View>
            {/* <View style={styles.chartContainer}>
                  <Image src={imgChartEncuesta}  />
            </View> */}
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]} >{convertHtmlToString(dataReporte.seccion6_recomenda_part)}</Text>
                <ComentariosEncuesta />
              </View>
            </View>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 7: RESULTADO DE LAS EVALUACIONES DE APRENDIZAJE</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]} />
              </View>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>Calificaciones</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
            <View style={[styles.col, styles.borderE, styles.head,{ flex: 0.3}]}>
                <Text style={[styles.center,styles.textCenter]}>No.</Text>
              </View>
              <View style={[styles.col, styles.borderE, styles.head, styles.flex2]}>
                <Text style={[styles.center,styles.textCenter]}>Nombre del participante</Text>
              </View>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>Diagnóstica</Text>
              </View>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>Final</Text>
              </View>
            </View>
            <CalificacionesParticipantes />

          </View>
        </View>
      </Page>

      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        
        <Image src={Logo} style={styles.img}/>

        {
          dataReporte.cliente.logo_cliente != null ?
            <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          : 
            null
        }

        <View style={styles.table}>
          <View style={[styles.borderX, styles.borderT]}>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 8: GRÁFICO DE EVALUACIONES</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col]}>
                <View style={styles.chartContainer}>
                  <Image src={imgChart}  />
                </View>
              </View>
            </View>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 9: REGISTRO DE ASISTENCIA AL CURSO</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>Nombre del participante</Text>
              </View>
              <View style={[styles.col, styles.borderE, styles.head]}>
                <Text style={[styles.center,styles.textCenter]}>Asistencia</Text>
              </View>
            </View>
            <AsistenciaParticipantes />

          <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 10: AVANCES LOGRADOS CON RELACIÓN A LOS RESULTADOS DE APRENDIZAJE PLANEADOS</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{dataReporte.seccion10_avances}</Text>
              </View>
            </View>

          </View>
        </View>
      </Page>

        <Page size="LETTER" wrap style={styles.page} scale="1.5">
          <Image src={Logo} style={styles.img}/>

          {
          dataReporte.cliente.logo_cliente != null ?
            <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          : 
            null
          } 

          <View style={styles.table}>
            <View style={[styles.borderX, styles.borderT]}>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 11: COMENTARIOS ADICIONALES</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{convertHtmlToString(dataReporte.seccion11_comentarios)}</Text>
              </View>
            </View>

            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 12: RECOMENDACIONES</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>{dataReporte.seccion12_recomend}</Text>
              </View>
            </View>

            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, {flex: 0.3}]}>
                <Text style={[styles.center]}>No.</Text>
              </View>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>Fotografía</Text>
              </View>
              <View style={[styles.col, styles.flex2]}>
                <Text style={[styles.center]}>Observación</Text>
              </View>
              <View style={[styles.col]}>
                <Text style={[styles.center]}>Referencia</Text>
              </View>
            </View>
            {
              arrayOfArrays[0] ?
              FotosRecomendaciones(arrayOfArrays[0])
              : null
            }

            </View>
          </View>
        </Page>


  {
    arrayOfArrays.length > 1 ?
        <Page size="LETTER" wrap style={styles.page} scale="1.5">
          <Image src={Logo} style={styles.img}/>

          {
          dataReporte.cliente.logo_cliente != null ?
            <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          : 
            null
          }
                  <View style={styles.table}>
            <View style={[styles.borderX, styles.borderT]}>
              {
                arrayOfArrays.slice(1,).map(item=>(
                  FotosRecomendaciones(item)
                ))
              }
            </View>
          </View>
        </Page>
        : null
  }

      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>

        {
          dataReporte.cliente.logo_cliente != null ?
            <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          : 
            null
        }

        <View style={styles.table}>
          <View style={[styles.borderX, styles.borderT]}>
            <View style={[styles.row, styles.borderB, styles.head]}>
              <View style={styles.col}>
                <Text style={[styles.center,styles.textCenter]}>SECCIÓN 12: MEMORIA FOTOGRÁFICA</Text>
              </View>
            </View>
            <FotosMemoria />

            <View style={[styles.row, styles.borderB]}>
              <View style={[styles.col, styles.borderE]}>
                <Text style={[styles.center,styles.textCenter]}>Atentamente</Text>
                <Text style={[styles.center,styles.textCenter]}>{dataReporte.administrador}</Text>
              </View>
              <View style={[styles.col, styles.borderE]}>
                <Text style={[styles.center,styles.textCenter]}>Firma</Text>
              </View>
            </View>

          </View>
        </View>
      </Page>

    </Document>
	);
}

PDFGeneratorInformeFinal.propTypes = {
  dataReporte: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
  imgChart: PropTypes.string.isRequired,
  imgChartEncuesta: PropTypes.string.isRequired,
  dataComentarios: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
};

export default PDFGeneratorInformeFinal;