/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import imageCompression from 'browser-image-compression';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Select from 'react-select';

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import { 
  USER_ADMIN, USER_LIDER_PC, 
  USER_INSTRUCTOR, 
  USER_LIDER_CAPACITACION, 
  USER_LIDER_SIPA, 
  USER_LIDER_CALIDAD, 
  USER_LIDER_BREMEX, 
  USER_RESPONSABLE_SUCURSAL 
} from "constans";

import {Post,Put,Get} from "../../tools/Helper";
import Message from "../../tools/Message";

function UserForm({editar, data, setShow, getLista, responsablesArr}) {

	const [formData, setFormData] = useState(data);
	const [responsables, setResponsables] = useState(responsablesArr);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const getData = async (dataUser) => {

    if (dataUser.id === undefined)
      return;
    
    setLoading(true);
    setStatus({ status: null, message: "" });
        
    const request = await Get(`user/${data.id}`);
  
    if (request.status === "success") {

      let newData = {};

      if(request.data[0].responsable){
        newData = {
          ...request.data[0],
          id_res: `res-${request.data[0].responsable.id_responsable}`,
          responsable: {  
            id_res:  `res-${request.data[0].responsable.id_responsable}`,
            nombre: request.data[0].responsable.nombre
          }
        }
      }
  
      if(request.data[0].firmante){
        newData = {
          ...request.data[0],
          id_res:  `ins-${request.data[0].firmante.id_firmante}`,
          responsable: {  
            id_res:  `ins-${request.data[0].firmante.id_firmante}`,
            nombre: request.data[0].firmante.nombre_completo
          }
        }
      }

      console.log("newData 2:");
      console.log(newData);
      
      setFormData(newData);    
    } else {
      setStatus({ status: request.status, message: "Error." });
    }
  
    setLoading(false);
  };

  useEffect(()=>{

    getData(data);
    setResponsables(responsablesArr);
    setStatus({status: null, message: ""});

  },[data,setFormData]);

	const handleChange = (e) =>{
	    setFormData({...formData, [e.target.name]:e.target.value});
	}

	const handleChangeSelect = (e, name, value) =>{
	    setFormData({...formData, [value]:e[value], [name]:e});
	}
  
  const handleFile = async (event) => {
    setStatus({
      status: 'warning',
      message: "Comprimiendo imagen...",
    });
    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);

      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      console.log(compressedFile);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile); 
      reader.onloadend = function() {
        const imgBase64data = reader.result;             
        setFormData({
          ...formData,
          firma: imgBase64data,
        });
        setStatus({
          status: null,
          message: "",
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const tempData = formData;
    const request = editar ? await Put(`users/${formData.id}`,formData) : await Post('users',formData) ;

    if(request.status === "success"){
      await getLista();
      if(editar){
        setFormData(tempData);
      }
    }
    setStatus(request);
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const adminRespuestas = [
    { admin: USER_ADMIN, nombre: "Administrador" },
    { admin: USER_LIDER_PC, nombre: "Coordinador de GIR" },
    { admin: USER_INSTRUCTOR, nombre: "Instructor" },
    { admin: USER_LIDER_CAPACITACION, nombre: "Coordinador de DTH" },
    { admin: USER_LIDER_SIPA, nombre: "Coordinador de SIPA" },
    { admin: USER_LIDER_CALIDAD, nombre: "Coordinador de Calidad" },
    { admin: USER_LIDER_BREMEX, nombre: "Coordinador de Bremex" },
    { admin: USER_RESPONSABLE_SUCURSAL, nombre: "Administrador de Sucursal" },
]

	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    {editar ? "Actualizar" : "Agregar"} Usuario del sistema
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  <MDButton variant="gradient" color="light" 
                    onClick={()=>setShow(true)}>
                    <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar a la lista
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : 
                <><Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Nombre o Alias" name="name" disabled={loading}
                      error={verifyError("name")} errorMsg={getError("name")}
                      value={formData.name} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Correo" name="email" disabled={loading}
                      error={verifyError("email")} errorMsg={getError("email")}
                      value={formData.email} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                	<MDTypography variant="caption" pt={3} pr={3}>Contraseña</MDTypography>
                  <MDInput type="text" label="Contraseña*" name="password" disabled={loading}
                      error={verifyError("password")} errorMsg={getError("password")}
                      value={formData.password} onChange={event => handleChange(event)} fullWidth />
                      <MDTypography variant="caption" pt={3} fontWeight="bold">*Dejar en blanco si no desea editar la contraseña</MDTypography>
                </Grid>
                <Grid item xs={6} mt="auto">
                  {
                    formData.firma ?
                      <img src={formData.firma} height={50} alt="firma" />
                    :
                    null
                  }
                    <MDTypography variant="caption" pt={3} pr={3}>Firma:</MDTypography>
                    <input type='file' accept="image/*" 
                      onChange={event => handleFile(event)}
                    />
                </Grid>
                <Grid item xs={6} mb="auto">
                  <MDTypography variant="caption" pt={3}>Responsable</MDTypography>
                  <Select className={`z-10 ${verifyError("id_res") ? "border-red" : ""}`}
                    options={responsables} placeholder="Nombre del responsable..."
                    value={formData.responsable} onChange={(e) => handleChangeSelect(e,"responsable","id_res")}
                    getOptionValue={(option) => `${option.id_res}`}
                    getOptionLabel={(option) => `${option.nombre}`}
                  />
	              </Grid>
	              <Grid item xs={6} mt="auto">
	              <MDTypography variant="caption" pt={3}>Permiso de usuario</MDTypography>
                <Select className={`z-10 ${verifyError("admin") ? "border-red" : ""}`}
	                options={adminRespuestas} placeholder="Permiso"
	                value={formData.is_admin} onChange={(e) => handleChangeSelect(e,"is_admin","admin")}
	                getOptionValue={(option) => `${option.admin}`}
	                getOptionLabel={(option) => `${option.nombre}`}
	              />
	              </Grid>
                <Grid item xs={6} mt="auto">
                  <MDButton variant="gradient" color="info" disabled={loading}
                      onClick={()=>doRequest()} fullWidth>
                      {editar ? "Actualizar" : "Agregar"}
                    </MDButton>
                </Grid></>}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
	);
}

UserForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  responsablesArr: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default UserForm;