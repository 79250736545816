import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPDF , { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image  } from '@react-pdf/renderer';
import { Divider } from '@mui/material';
import Logo from './logo.png';
import Logo2 from './logo2.jpg';

function PDFGeneratorListaAsistencia({dataReporte}) {

	const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 7,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.1,
        flexDirection: 'column',
        display:"flex",
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    table:{
      width:"97%",
      height:"84%",
      display:"flex",
      borderColor:"#000000",
      marginLeft:"auto",
      marginRight:"auto",
      marginTop:"auto",
      marginBottom:35,
      fontSize: 9
    },

    row:{
      width: "100%",
      borderColor:"#000000",
      flexDirection: 'row',
    },

    flexY:{
      flex: "1 1 auto",
    },

    col:{
      borderColor:"#000000",
      flex:1, 
      paddingTop:0,
    },

    flex5:{
      flex:4,
    },

    borderX:{
      borderRight:1,
      borderLeft:1,
    },

    borderY:{
      borderTop:1,
      borderBottom:1,
    },

    borderS:{
      borderLeft:1,
    },

    borderE:{
      borderRight:1,
    },

    borderT:{
      borderTop:1,
    },

    borderB:{
      borderBottom:1,
    },

    center:{
      verticalAlign:"middle",
      marginTop:"auto",
      marginBottom:"auto",
      paddingLeft:10,
      paddingRight:8,
      paddingTop: 4,
      paddingBottom: 4,
    },

    textCenter:{
      textAlign:"center",
    },

    textLeft:{
      textAlign: 'left'
    }, 

    textUpperCase:{
      textTransform: 'uppercase'
    }, 

    py10:{
      paddingTop:30,
      paddingBottom:30,
    },

    topSection:{
      marginTop: 30
    },

    flex15:{
      flex:1.5,
    },

    flex2:{
      flex:2,
    },

    head:{
      fontWeight:"heavy",
      backgroundColor:"#dedede",
      fontFamily: 'Helvetica-Bold'
    },

    img:{
      display:"block",
      position:"absolute",
      width:"12%",
      left:90,
      top: 25,
    },

    img2:{
      display:"block",
      position:"absolute",
      height:50,
      right:90,
      top: 25,
    },

    red:{
      color:"#ff0004"
    },

    textSmall:{
      fontSize: 8,
    },

    alend:{
      textAlign:"right"
    },

    wNo:{
      width: 30
    },

    pagina:{
      position:"fixed",
      bottom:15,
      left:0,
      paddingRight:28,
      textAlign:"right",
      fontSize:7,
    }

  });

  const pageAsistencia = (init, end) =>(
    <Page size="LETTER" orientation="landscape" wrap style={styles.page} scale="1.5">
      <Image src={Logo} style={styles.img}/>

    {
      dataReporte.cliente.logo_cliente ?
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
      : null
    }

      <View style={styles.table}>
        <View style={[styles.borderX, styles.borderT]}>
          <View style={[styles.row, styles.borderB, styles.head]}>
            <View style={styles.col}>
              <Text style={[styles.center,styles.textCenter]}>LISTA DE ASISTENCIA AL CURSO </Text>
            </View>
          </View>
          <View style={[styles.row, styles.borderB]}>
            <View style={[styles.col, styles.borderE, styles.head]}>
              <Text style={[styles.center]}>NOMBRE DEL CURSO:</Text>
            </View>
            <View style={[styles.col, styles.flex2]}>
              <Text style={[styles.center]}>{dataReporte.curso.nombre}</Text>
            </View>
          </View>
          <View style={[styles.row, styles.borderB]}>
            <View style={[styles.col, styles.borderE, styles.head]}>
              <Text style={[styles.center,styles.textCenter]}>LUGAR CAPACITACIÓN</Text>
            </View>
            <View style={[styles.col, styles.borderE, styles.head]}>
              <Text style={[styles.center,styles.textCenter]}>FECHA</Text>
            </View>
            <View style={[styles.col, styles.borderE, styles.flex15, styles.head]}>
              <Text style={[styles.center,styles.textCenter]}>INSTRUCTOR</Text>
            </View>
            <View style={[styles.col, styles.head]}>
              <Text style={[styles.center,styles.textCenter]}>FIRMA</Text>
            </View>
          </View>
          <View style={[styles.row, styles.borderB]}>
            <View style={[styles.col, styles.borderE]}>
              <Text style={[styles.center, styles.textCenter, styles.textUpperCase]}>{dataReporte.municipio_alcaldia}, {dataReporte.estado}</Text>
            </View>
            <View style={[styles.col, styles.borderE]}>
              <Text style={[styles.center,styles.textCenter, styles.textUpperCase]}>{dataReporte.fecha_inicio}</Text>
            </View>
            <View style={[styles.col, styles.borderE, styles.flex15]}>
              <Text style={[styles.center,styles.textCenter, styles.textUpperCase]}>{dataReporte.instructor}</Text>
            </View>
            <View style={[styles.col]}>
              <Text style={[styles.center,styles.textCenter]} />
            </View>
          </View>
        </View>
        <Divider style={[styles.topSection]} />
        <View style={[styles.borderX]}>
          <View style={[styles.row, styles.borderB, styles.borderT]}>
            <View style={[styles.wNo, styles.head]}>
              <Text style={[styles.textCenter]}/>
            </View>
            <View style={[styles.col, styles.borderE, styles.flex15, styles.head]}>
              <Text style={[styles.center,styles.textCenter]}>NOMBRE</Text>
            </View>
            <View style={[styles.col, styles.borderE, styles.head]}>
              <Text style={[styles.center,styles.textCenter]}>CARGO/PUESTO</Text>
            </View>
            {
              dataReporte.programa_interno === 'SI' ? 
                <View style={[styles.col, styles.borderE, styles.head]}>
                  <Text style={[styles.center,styles.textCenter]}>PUESTO EN LA BRIGADA</Text>
                </View>
              : null
            }
            <View style={[styles.col, styles.borderE, styles.head]}>
              <Text style={[styles.center,styles.textCenter]}>CURP</Text>
            </View>
            <View style={[styles.col, styles.head]}>
              <Text style={[styles.center,styles.textCenter]}>FIRMA ASISTENCIA</Text>
            </View>
          </View>
          {
            dataReporte.cursante_programado.slice(init,end).map((item, index)=>(
              <View key={item.id_cursante_programado} style={[styles.row, styles.borderB]}>
                <View style={[styles.borderE, styles.wNo]}>
                  <Text style={[styles.center,styles.textLeft, styles.textUpperCase, styles.textSmall]}>{index + 1}</Text>
                </View>
                <View style={[styles.col, styles.borderE, styles.flex15]}>
                  <Text style={[styles.center,styles.textLeft, styles.textUpperCase, styles.textSmall]}>{item.cursante.nombre_completo}</Text>
                </View>
                <View style={[styles.col, styles.borderE]}>
                  <Text style={[styles.center,styles.textCenter, styles.textUpperCase, styles.textSmall]}>{item.cursante.puesto ? item.cursante.puesto : ''}</Text>
                </View>
                {
                  dataReporte.programa_interno === 'SI' ? 
                    <View style={[styles.col, styles.borderE]}>
                      <Text style={[styles.center,styles.textCenter, styles.textUpperCase, styles.textSmall]}>{item.cursante.puesto_brigada ? item.cursante.puesto_brigada.descripcion : ''}</Text>
                    </View>
                  : null
                }
                <View style={[styles.col, styles.borderE]}>
                  <Text style={[styles.center, styles.textCenter, styles.textUpperCase, styles.textSmall]}>{item.cursante.curp ? item.cursante.curp : ''}</Text>
                </View>
                <View style={[styles.col]}>
                  <Text style={[styles.center,styles.textCenter]}/>
                </View>
              </View>
            ))
          }
        </View>
      </View>
    </Page>
  )

	return (
    <Document>

{
dataReporte.cursante_programado.length <= 15 ?
  pageAsistencia(0,15)
  : null
}

{
  dataReporte.cursante_programado.length > 15 && dataReporte.cursante_programado.length <= 30 ?
  <>
  {pageAsistencia(0,15)}
  {pageAsistencia(15,30)}
  </>
  : null
}

{
  dataReporte.cursante_programado.length > 30 && dataReporte.cursante_programado.length <= 45 ?
  <>
  {pageAsistencia(0,15)}
  {pageAsistencia(15,30)}
  {pageAsistencia(30,45)}
  </>
  : null
}

{
  dataReporte.cursante_programado.length > 45 && dataReporte.cursante_programado.length <= 60 ?
  <>
  {pageAsistencia(0,15)}
  {pageAsistencia(15,30)}
  {pageAsistencia(30,45)}
  {pageAsistencia(45,60)}
  </>
  : null
}


    </Document>
	);
}

PDFGeneratorListaAsistencia.propTypes = {
  dataReporte: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
};

export default PDFGeneratorListaAsistencia;