/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';
import { useParams, Link } from 'react-router-dom';

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { PDFDownloadLink, pdf } from '@react-pdf/renderer';  
import PDFGenerator from "../../tools/PDFGenerator";

import Servicios from '../tables/Servicios';

import {Get,Put,PostMulti} from "../../tools/Helper";
import Message from "../../tools/Message";

function VerDetalles() {

	const animatedComponents = makeAnimated();
	const scrollRef = useRef(React.createRef());

	const today = new Date();
	const zeroDate = today.getDate() < 9 ? "0" : "";
	const zeroMonth = today.getMonth() < 9 ? "0" : "";
	const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;
	const [data, setData] = useState([]);

	const { id } = useParams();


	const [formData, setFormData] = useState({
			numero:'',
			num_cotizacion:'',
			fecha_oss:datestring,
			elabora:'',
			elabora_puesto:'',
			acepta:'',
			responsables: undefined,
			info_adicional:'',
			comentario: 'La presente orden de servicio y/o suministros (OSS) define implícitamente un deber para Paymun S.A. de C.V. de realizar/suministrar los servicios/productos descritos en el Apartado B, así mismo; implica el deber del Contratante (Apartado A) de realizar el pago correspondiente de acuerdo a los términos comerciales consentidos por ambas partes, en el entendido que al momento de emitir la presente OSS el contratante ha dado su consentimiento y autorización para realizar/suministrar los servicios/productos solicitados mediante la cotización referenciada.',
		});
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState({status: null, message: ""});
	const [serviciosArray, setServiciosArray] = useState([]);

	const [clientes, setClientes] = useState([]);
	const [servicios, setServicios] = useState([]);
	const [responsables, setResponsables] = useState([]);


	const setServArr = (d) =>{
		setServiciosArray(d);
	}

	useEffect(()=>{
		const getData = async e =>{
		
			setStatus({status: null, message: ""});
			setLoading(true);

			const request = await Get(`oss/${id}`);

			if(request.status === "success"){
				setFormData(request.data)
			}else{
				setStatus({status:request.status, message:"Error."});
			}

			setLoading(false);
		}

		getData();
	},[]);

	const handleChange = (e, name = "", value = "") =>{
		if(name === ""){
			setFormData({...formData, [e.target.name]:e.target.value});
		}else{
			setFormData({...formData, [value]:e[value], [name]:e});
		}
	}

	const verifyError = (name) =>{
		if("errors" in status){
			if(name in status.errors){
				return true;
			}
		}

		return false;
	}

	const getError = (name) =>{
		if("errors" in status){
			if(name in status.errors){
				return status.errors[name][0];
			}
		}

		return "";
	}

		const responsablesLista = formData.responsables ? formData.responsables.map((responsable)=>
			<>
				<MDTypography variant="h6" display="inline" mr="auto">
					{responsable.nombre}
				</MDTypography>
					<MDTypography variant="p" display="block" mr="auto">
						{responsable.puesto}
					</MDTypography>
				</>
		) : "";      

		const serviciosLista = formData.servicios ? formData.servicios.map((servicio)=>
  		<>
	  		<MDTypography variant="h6" display="inline" mr="auto">
	    		{servicio.descripcion}
	    	</MDTypography>
	      	<MDTypography variant="p" display="block" mr="auto">
	      		Cantidad: {servicio.cantidad}
	      	</MDTypography>
      	</>
  	) : "";  

  	const loadingMsg = () => <MDBox display="block" fullWidth textAlign="center" p={5}>
		<CircularProgress size="10rem" /> 
		<MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
	</MDBox>   


	return (
		<DashboardLayout>
			<DashboardNavbar />
				<MDBox pt={6} pb={3} display="block">
        			<Grid container spacing={6}>
        				<Grid item xs={12} ref={scrollRef}>
							<Fade in timeout={800}>
							{loading ? loadingMsg() :
								<Card>
									<MDBox mx={2} mt={-3} py={3} px={2}
										variant="gradient" bgColor="info"
										borderRadius="lg" coloredShadow="info"
									>
									<Grid container spacing={6}>
										<Grid item xs={6}>
											<MDTypography variant="h4" display="inline" mr="auto" color="white">
												OSS N° {formData.id_oss}
											</MDTypography>
										</Grid>
										<Grid item xs={6} textAlign="right" >
											<Link to="/mis-oss">
											<MDButton variant="gradient" color="light" >
												<Icon fontSize="small">arrow_back</Icon>
													&nbsp;Regresar
											</MDButton>
											</Link>
										</Grid>
									</Grid>
								</MDBox>
									<MDBox pt={3}>
										<MDBox pb={3} px={3}>
											<MDBox>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Message status={status} />
													</Grid>
													<Grid item xs={4} mt="auto">
														<MDBox>
															<MDTypography variant="h6" display="inline" mr="auto">
																No. Cotizacion
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.num_cotizacion}
																</MDTypography>
														</MDBox>
													</Grid>
													<Grid item xs={4} mt="auto">
														<MDBox>
															<MDTypography variant="h6" display="inline" mr="auto">
																Fecha
															</MDTypography>
															<MDTypography variant="p" display="block" mr="auto">
																	{formData.fecha_oss}
																</MDTypography>
														</MDBox>
													</Grid>
													<Grid item xs={12}>
														<MDBox>
															<MDTypography variant="h6" display="inline" mr="auto">
																Comentario
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.comentario}
																</MDTypography>
														</MDBox>
													</Grid>
													<Grid item xs={4} mt="auto">
														<MDBox>
															<MDTypography variant="h6" display="inline" mr="auto">
																Direccion
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.direccion}
																</MDTypography>
														</MDBox>
													</Grid>
													<Grid item xs={4} mt="auto">
														<MDBox>
															<MDTypography variant="h6" display="inline" mr="auto">
																Estado
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.estado}
																</MDTypography>
														</MDBox>
													</Grid>
													<Grid item xs={4} mt="auto">
														<MDBox>
															<MDTypography variant="h6" display="inline" mr="auto">
																Municipio
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.municipio}
																</MDTypography>
														</MDBox>
													</Grid>
												</Grid>
												<Divider />
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<MDBox mb={2}>
															<MDTypography variant="h6" display="inline" mr="auto">
																Cliente
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.cliente ? formData.cliente.nombre_comercial : ""}
																</MDTypography>
														</MDBox>
													</Grid>
												</Grid>
												<Divider />
												{serviciosLista}
												<Divider />
												<Grid item xs={12}>
													<MDBox mb={2}>
														<MDTypography variant="h5" display="inline" mr="auto">
														Información Adicional
													</MDTypography>
														<MDTypography variant="p" display="block" mr="auto">
															{formData.info_adicional}
														</MDTypography>
													</MDBox>
												</Grid>
												<Divider />
												{
													// <Responsables responsableArray={responsableArray} setResponsableArray={setResponsableArray}/>
												}
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<MDBox mb={2}>
														<MDTypography variant="h5" pb={1}>
															Responsables
														</MDTypography>

															{responsablesLista}
														</MDBox>
													</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<MDBox>
															<MDTypography variant="h5" pb={1}>
																Firma de Autorizacion
															</MDTypography>
															<MDTypography variant="h6" display="inline" mr="auto">
																Nombre de quien elabora
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.elabora}
																</MDTypography>
														</MDBox>
													</Grid>
													<Grid item xs={6} mt="auto">
														<MDBox>
															<MDTypography variant="h6" display="inline" mr="auto">
																Puesto
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.elabora_puesto}
																</MDTypography>
								 
														</MDBox>
													</Grid>
													<Grid item xs={6}>
														<MDBox>
															<MDTypography variant="h6" display="inline" mr="auto">
																Nombre de quien acepta
															</MDTypography>
																<MDTypography variant="p" display="block" mr="auto">
																	{formData.acepta}
																</MDTypography>
														</MDBox>
													</Grid>
													<Grid item xs={12}>
														<PDFDownloadLink document={<PDFGenerator data={formData} />} fileName={`oss_${formData.id_oss}`}>
															<MDButton variant="gradient" color="success">
																<Icon>picture_as_pdf</Icon> Generar PDF
															</MDButton>
														</PDFDownloadLink>
													</Grid>
												</Grid>
											</MDBox>
										</MDBox>
									</MDBox>
								</Card>}
							</Fade>
						</Grid>
					</Grid>
				</MDBox>
			</DashboardLayout>
	);
}

export default VerDetalles;