
export function getDateLarge(date) {
    const newDate = new Date(`${date}T00:00:00-06:00`);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return newDate.toLocaleDateString('es-MX', options)
}

export function getHoursLarge(datetime) {
    const newDatetime = new Date(datetime);
	return newDatetime.getHours('es-MX')
}

export function parseCurrency(value) {
    let newValue = value ?? 0;

    if(value){
        if((value).toString() === 'NaN'){
            newValue = 0;
        }
    }
    const formatCurrency = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      });
      
      return formatCurrency.format(newValue);
}
