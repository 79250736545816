/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";


import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";

import { useSearchParams, useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import Message from "../../tools/Message";

import FormActualizar from "../cursantes/Form";

import {Get, Del, GetGuest, Post} from "../../tools/Helper";

function CursanteExternoCursos() {

  const emptyForm = {
    nombre_completo:'',
    curp:'',
    ocupacion:'',
    puesto:'',
    correo:'',
    sexo:'',
    edad:'',
    tipo_sangre:'',
    empresa:'',
    escolaridad:'',
    calle_num:'',
    municipio:'',
    estado:'',
    nombre_emergencia:'',
    telefono_emergencia:'',
    telefono:'',
    sexo_arr: [],
    escolaridad_arr: [],
    id_ocupacion: "",
    evidencias: {
      curp: '',
      curp_nombre: '',
      foto: '',
      foto_nombre: '',
      ine: '',
      ine_nombre: '',
    },
    cursos: [],
    acepta: false,
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [selectedDelete, setSelectedDelete] = useState(null);

  const [cursos, setCursos] = useState([]);
  const [ocupaciones, setOcupaciones] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [puestos, setPuestos] = useState([]);

  const [isGuest, setIsGuest] = useState(false);

  const crearButton = () =>{
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const [data, setData] = useState([]);
  const [email, setEmail] = useState('');
  const [existValidEmail, setExistValidEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const tokenUserExternal = searchParams.get("tk");

  const navigate = useNavigate();

  const getDataGuest = async () =>{
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await GetGuest(`cursante-invitado/${tokenUserExternal}`);

    if(request.status === "success"){
      setCursos(request.data.cursos);
      setOcupaciones(request.data.ocupaciones);
      setClientes(request.data.clientes);
      setPuestos(request.data.puestos_brigadas)
    }else{
      setStatus({status:"error", message:"No tienes permiso."});
    }

    setSelectedData(emptyForm);
    setLoading(false);

  }

  const validateUserExternal = () =>{
    const user = JSON.parse(localStorage.getItem('user'));

    if(tokenUserExternal){
      setIsGuest(true);
      crearButton();
      getDataGuest();
      return;
    }

    if(!user){
      navigate("/login");
    }
  }

  const verificaCorreo =async()=>{

    if(email === ''){
        setStatus({status: 'error', message: "Escriba un correo válido"})
        return;
    }
    const newData = {
      email,
      token: tokenUserExternal
    }
    
    const request =  await Post(`valida-email-cursante-curso`, newData);
    if(request.status === 'error'){
        setStatus({status: 'warning', message: "Escriba un correo válido"})
        return;
    }
    if(request.status === 'warning'){
      setStatus({status: 'warning', message: "No se agregó al curso, límite de participantes completado."})
      return;
  }
    if(request.status === 'empty'){
        setStatus({status: 'warning', message: "Por favor registre sus datos"})
        setExistValidEmail(true);
        return;
    }

    setStatus(request);
}


const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

const getError = (name) =>{
    if("errors" in status){
        if(name in status.errors){
        return status.errors[name][0];
        }
    }

return "";
}

  useEffect(()=>{
    validateUserExternal();
  },[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={!show} timeout={800}>
        <Collapse in={!show}  timeout={50}>
        <Card>
            <MDBox mx={2} mt={-3} py={3} px={2}>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={6} mt={5}>
                        <MDTypography variant="p" pt={3} pr={3}>Correo electrónico <span style={{ color: 'red' }}>*</span></MDTypography>
                        <MDInput type="email" label="" name="email"
                        error={verifyError("email")} errorMsg={getError("email")}
                        value={email.email} onChange={(e)=>{
                            setEmail(e.target.value)
                        }} fullWidth />
                    </Grid>
                    <Grid item xs={6} md={6} mt={9}>
                        <MDButton md={4} variant="gradient" color="info" style={{ color: 'white', borderRadius: 5 }}
                            onClick = {()=>verificaCorreo()}
                            fullWidth >
                            Agregarme al curso <Icon fontSize="medium">save</Icon>
                        </MDButton>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
        <Message status={status} />
        { !show && existValidEmail ? 
            <FormActualizar data={selectedData} editar={editar} setShow={setShow} isGuest={isGuest}
            getLista={getDataGuest} cursos={cursos} ocupaciones={ocupaciones} clientes={clientes} puestos = {puestos} statusMessage={status} /> : ""
        }
        </Collapse>
        </Fade>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CursanteExternoCursos;