import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Select from 'react-select';
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {Get, Del, GetGuest, Post} from "../../tools/Helper";

import Message from "../../tools/Message";


function TablaCursantes({ dataCurso, getCursantesCursos, dataCursantes, dataCursantesCurso }) {
  const [status, setStatus] = useState({status: null, message: ""});
  const [data, setData] = useState(dataCursantes);


  const addCursanteCurso = async (IDCursante) =>{
    setStatus({status: null, message: ""});
    const numCursantes = dataCursantes.filter(item => item.agregado === 1);
    if(numCursantes.length >= parseInt(dataCurso.num_participantes,10)){
        setStatus({status: "warning", message: "Ya ha llegado al límite de participantes"});
      return;
    }
  
    const dataCursante = {
      id_curso_programado: dataCurso.id_curso_programado,
      id_cursante: IDCursante
    }

    const request = await Post(`cursante-programado`, dataCursante);
    if(request.status === "success"){
      getCursantesCursos();
      return;
    }
    setStatus({status:request.status, message:"Error."});
  }

  const DeleteCursanteCurso = async (IDCursante) =>{
    setStatus({status: null, message: ""});
    const dataCursante = {
      id_curso_programado: dataCurso.id_curso_programado,
      id_cursante: IDCursante
    }

    const request = await Del(`cursante-programado/${IDCursante}`);
    if(request.status === "success"){
      getCursantesCursos();
      return;
    }
    setStatus({status:request.status, message:"Error."});
  }

  const actionCursante=(IDCursante, agregado, IDCursanteProgramado)=>{
    if(agregado === 1){
      DeleteCursanteCurso(IDCursanteProgramado);
      return;
    }
    addCursanteCurso(IDCursante);
  }

  const editButton = (row) => <MDButton variant="gradient" 
  color={row.agregado === 0 ? 'light' : 'info'} iconOnly onClick={()=>actionCursante(row.id_cursante, row.agregado, row.id_cursante_programado)}><Icon>{row.agregado===0 ? 'check_box_outline_blank' : 'done'}</Icon></MDButton>;

  const columns = [
    {
        name: 'Nombre',
        selector: row => row.nombre_completo,
    },
    {
      name: '',
      selector: row => editButton(row),
      grow:0,
      compact: true,
    }
  ];

  const tableData = {
    columns,
    data
  };

  return (
    <MDBox>
        <Message status={status} />
        <p>Seleccione los participantes que desea agregar al curso</p>
        <DataTableExtensions
        {...tableData}
        export={false}
        print={false}
        filterPlaceholder="Buscar..."
        >
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationComponentOptions={{
            rowsPerPageText: 'Cursante por página:', 
            rangeSeparatorText: 'de', 
            noRowsPerPage: false, 
            selectAllRowsItem: true, 
            selectAllRowsItemText: 'Todos'
            }}
            noDataComponent={<MDBox py={5}>No se encontraron participantes.</MDBox>}
        />
        </DataTableExtensions>
    </MDBox>
  );
}

TablaCursantes.propTypes = {
    dataCursantes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    dataCursantesCurso: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    dataCurso: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    getCursantesCursos: PropTypes.func.isRequired,
  };

export default TablaCursantes;
