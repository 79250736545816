/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import Select from 'react-select';

import {Post,Put} from "../../tools/Helper";
import Message from "../../tools/Message";

function CondicionesForm({editar, data, setShow, getLista, grupo}) {

	const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

	useEffect(()=>{
    setFormData(data);
    setStatus({status: null, message: ""});
  },[data,setFormData]);

  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
      setFormData({...formData, [e.target.name]:e.target.value});
    }else{
      setFormData({...formData, [value]:e[value], [name]:e});
    }
  }

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const tempData = formData;

    const request = editar ? await Put(`condiciones/${formData.id_condicion_comercial}`,formData) : await Post('condiciones',formData) ;

    if(request.status === "success"){
      await getLista();
      if(editar){
        setFormData(tempData);
      }
    }


    setStatus(request);
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const getGrupo = () => grupo.filter(item=>item.id_grupo_condiciones_comerciales === formData.id_grupo_condiciones_comerciales)[0];


	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    {editar ? "Actualizar" : "Agregar"} Condición
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  <MDButton variant="gradient" color="light" 
                    onClick={()=>setShow(true)}>
                    <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar a la lista
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : <><Grid item xs={6} mt="auto">
                  <MDInput multiline type="textarea" rows={2}  label="Descripción" name="descripcion"
                      error={verifyError("descripcion")} errorMsg={getError("descripcion")}
                      value={formData.descripcion} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                        <MDTypography variant="caption" pt={3}>Grupo:</MDTypography>
                        <small className="font-red">{getError("id_grupo_condiciones_comerciales")}</small>
                        <Select className={`z-10 ${verifyError("id_grupo_condiciones_comerciales") ? "border-red" : ""}`}
                            options={grupo} placeholder="Seleccion un grupo de condiciones..."
                            value={getGrupo()} onChange={(e) => handleChange(e,"grupo","id_grupo_condiciones_comerciales")}
                            getOptionValue={(option) => `${option.id_grupo_condiciones_comerciales}`}
                            getOptionLabel={(option) => `${option.descripcion}`}
                          />
                      </Grid>
                <Grid item xs={6}>
                  <MDButton variant="gradient" color="info" disabled={loading}
                      onClick={()=>doRequest()} fullWidth>
                      {editar ? "Actualizar" : "Agregar"}
                    </MDButton>
                </Grid></>}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
	);
}

CondicionesForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  grupo: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default CondicionesForm;