/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import { useParams, Link, useNavigate } from 'react-router-dom';

import EvidenciaCard from "../../examples/Cards/InfoCards/EvidenciaCard";

import {Get} from "../../tools/Helper";
import Message from "../../tools/Message";


function Evidencias() {

  const [formData, setFormData] = useState([]);
  const { id, idServ } = useParams();
  const navigate = useNavigate();

  const handleChange = (e) =>{
    setFormData({...formData, [e.target.name]:e.target.value});
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [totalServicios, setTotalServicios] = useState([]);
  const [prevPage, setPrevPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [reload, setReload] = useState(true);

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const idServicio = idServ === "inicio" ? 0 : idServ;
    const request = await Get(`evidencias/${id}/${idServ}`);

    if(request.status === "success"){
      setData(request.data);
      setTotalServicios(request.total);
      // setStatus({status:request.status, message:"Se ha creado exitosamente."});
    }else{
      setStatus({status:request.status, message:"Error."});
    }

    setLoading(false);
  }

  useEffect(()=>{
    if(data.length > 0){
      if(totalServicios.length > 0){
        
        const currentIndex = totalServicios.indexOf(data[0].id_servicio);
        
        if(currentIndex !== 0){
          setPrevPage(totalServicios[currentIndex-1]);
        }else{
          setPrevPage(0);
        }
        if(currentIndex !== totalServicios.length-1){
          setNextPage(totalServicios[currentIndex+1]);
        }else{
          setNextPage(0)
        }
      }
    }
  },[data, totalServicios]);

  useEffect(()=>{
    if(data.length > 0){
      setData([]);
      setReload(true);
    }
  },[idServ]);

  useEffect(()=>{
    if(reload){
      getData();
      setReload(false);
    }
  },[reload]);

  const listaOss2 = () => data.length > 0 ? 
    <Grid item xs={12} lg={12}>
      <EvidenciaCard
        data={data[0]}
      /> 
    </Grid> : "";

  const listaOss = () => data.length > 0 ? data.map((el, index)=>
      <Grid item xs={12} lg={12}>
        <EvidenciaCard
          data={data[index]}
        />
      </Grid>
    ) : <Grid item xs={12}><Card><MDBox p={4}>No se han encontrado OSS asignados a tu cuenta.</MDBox></Card></Grid>;

  const cargando = () => <Grid item xs={12} lg={12}>
    <Card>
      <MDBox item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
        <CircularProgress size="6.66rem" /> 
        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
      </MDBox>
    </Card>
  </Grid>

  const navigateTo = (num) =>{
    if(num !== 0){
      navigate(`/evidencias/${id}/servicio/${num}`);
    }
  }

  const ossNumber = () => data.length > 0 ? `OSS N° ${data[0].id_oss}` : "";

  const content = () => data.length > 0 ? <Grid container spacing={2} py={4}>
      <Grid item xs={2} textAlign="center">
        
        <MDButton variant="gradient" color={prevPage === 0 ? "secondary" : "info"} 
          fullWidth disabled={prevPage === 0} onClick={()=>navigateTo(prevPage)}>
          <Icon>west</Icon> Anterior
        </MDButton>
        
      </Grid>
        <Grid item xs={8} my="auto" px={2}>
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize">
            {data.length > 0 ? `N° ${totalServicios.indexOf(data[0].id_servicio) + 1} - ${data[0].servicio.clave}: ${data[0].servicio.descripcion}` : ""}
          </MDTypography>
        </Grid>
      <Grid item xs={2} textAlign="center">
        
        <MDButton variant="gradient" color={nextPage === 0 ? "secondary" : "info"} 
          fullWidth disabled={nextPage === 0} onClick={()=>navigateTo(nextPage)}>
          Siguiente <Icon>east</Icon>
        </MDButton>
        
      </Grid>
      {listaOss2()}
    </Grid> : ""

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3} display="block">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Fade in timeout={800}>
            <Collapse in timeout={50}>
            <MDBox
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      {loading ? "Cargando..." : ossNumber()}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" px={4}>
                    <Link to="/mis-oss">
                      <MDButton variant="gradient" color="light">
                        <Icon fontSize="small">arrow_back</Icon>
                          &nbsp;Regresar
                      </MDButton>
                    </Link>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white" pl={3}>
                      Total: {totalServicios.length}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              {
                loading ? cargando() :
                content()
              }
            </Collapse>
            </Fade> 
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Evidencias;
