import {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import ReactPDF, { pdf, renderToFile } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PDFGenerator from './tools/PDFGenerator';
import { Get } from './tools/Helper';


function DirectDownload() {

const params = useParams();
const [data, setData] = useState([]);

  useEffect(()=>{
	const getData = async e =>{

		const request = await Get(`oss/${params.id}`);

		if(request.status === "success"){
		setData(request.data);
		}

	}

    getData();


  },[]);

  useEffect(()=>{

  	const generate = async e =>{
  		console.log("aaaa")
  		const doc = <PDFGenerator data={data} />;
  		const asPdf = pdf(); // {} is important, throws without an argument
	      asPdf.updateContainer(doc);
	      const blob = await asPdf.toBlob();
	      saveAs(blob, 'document.pdf');
	      await renderToFile(doc, `${__dirname}/my-doc.pdf`);
  	}

  	if(data.id_oss){
  		generate();
  		window.close();
  	}

  },[data]);

	    // saveAs(blob, fileName);

return(<button type="button"
    onClick={async () => {

    console.log(data);
      const doc = <PDFGenerator data={data} />;

      const asPdf = pdf(); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'document.pdf');
    }}
  >Download PDF</button>);


}

export default DirectDownload;