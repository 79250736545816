/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import Icon from "@mui/material/Icon";

import QRCode from 'qrcode';

import Modal from 'react-modal';
import Form from "./Form";
import {Get, Del} from "../../tools/Helper";
import Message from "../../tools/Message";
import BoxLinksRegister from "../../components/BoxLinksRegister";

function Curso() {

  const emptyForm = {
    nombre_completo:'',
    curp:'',
    ocupacion:'',
    puesto:'',
    correo:'',
    sexo:'',
    edad:'',
    tipo_sangre:'',
    empresa:'',
    escolaridad:'',
    calle_num:'',
    municipio:'',
    estado:'',
    nombre_emergencia:'',
    telefono_emergencia:'',
    telefono:'',
    sexo_arr: [],
    escolaridad_arr: [],
    id_ocupacion: "",
    evidencias: {
      curp: '',
      curp_nombre: '',
      foto: '',
      foto_nombre: '',
      ine: '',
      ine_nombre: '',
    },
    cursos: [],
    acepta: false,
  };

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [selectedDelete, setSelectedDelete] = useState(null);

  const [cursos, setCursos] = useState([]);
  const [ocupaciones, setOcupaciones] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [domain, setDomain] = useState('');
  const [userLogin, setUserLogin] = useState({});

  const editarButton = (row) =>{
    console.log(row);
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const crearButton = () =>{
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [statusCopy, setStatusCopy] = useState({status: null, message: ""});
  const [qr, setQr] = useState(null);

  const getData = async e =>{
    const {user} = JSON.parse(localStorage.getItem('user'));

    setStatus({status: null, message: ""});
    setLoading(true);

    let request = await Get(`cursantes`);

    if(user.tipo === 'cliente'){
        request = await Get(`cursantes-cliente-externo/${user.id_cliente}`);
    }

    if(request.status === "success"){
      setData(request.data.cursantes);
      setCursos(request.data.cursos);
      setOcupaciones(request.data.ocupaciones);
      setClientes(request.data.clientes);
      setPuestos(request.data.puestos_brigadas)
    }else{
      setStatus({status:request.status, message:"Error."});
    }

    setSelectedData(emptyForm);
    setLoading(false);
  }

  const generateQRLinkEmpresa = async (domainName, user) => {
    const url = `${domainName}/cursante-invitado-empresa?empresa=true&tk=${user.token_guest}`;
    const qrData = await QRCode.toDataURL(url);
    await setQr(qrData);
  }


  useEffect(()=>{
    const fullpath = window.location.href;
    const regex = window.location.pathname;
    const domainName= fullpath.replace(regex, '');
    const {user} = JSON.parse(localStorage.getItem('user'));
    setUserLogin(user);
    setDomain(domainName);
    getData();
    generateQRLinkEmpresa(domainName, user);
  },[]);


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete(null);
  }

  function openModal(idPregunta) {
    setIsOpen(true);
    setSelectedDelete(idPregunta);
  }

  const deleteCurso = async () =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`cursantes/${selectedDelete}`);

    if(request.status === "success"){
      await getData();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const getCursos = (row) => row.map((el)=>
    <p>{el.curso.nombre}</p>
  );

  const copyLink = () =>{
    navigator.clipboard.writeText(`${domain}/cursante-invitado-empresa?empresa=true&tk=${userLogin.token_guest}`);
    setStatusCopy({status: 'success', message: 'Enlace copiado en portapapeles...'});
  }

  const editButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const deleteButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>openModal(row.id_cursante)}><Icon>deleteForever</Icon></MDButton>;

  const columns = [
    {
        name: 'Nombre completo',
        selector: row => row.nombre_completo,
        grow:3,
        sortable: true,
    },
    {
        name: 'CURP',
        selector: row => row.curp,
        wrap:true,
        grow:2,
    },
    {
        name: 'Correo',
        selector: row => row.correo,
        grow:2
    },
    {
      name: ! userLogin.admin ? 'Teléfono' : 'Empresa',
      selector: row => ! userLogin.admin ? row.telefono : row.nombre_comercial,
      grow:3
    },
    {
        name: '',
        selector: row => editButton(row),
        grow:0,
        compact: true,
    },
    {
        name: '',
        selector: row => deleteButton(row),
        grow:0,
        compact: true,
    },
  ];

  const tableData = {
    columns,
    data,
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
                <Grid container spacing={12} className="headerShareLink">
                  <Grid item md={3} className="headerTitleShareLink" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                      ! userLogin.admin ?
                        <MDTypography variant="h4" display="inline" mr="auto" color="white">
                          Lista de personal
                        </MDTypography>
                        :
                      <MDTypography variant="h4" display="inline" mr="auto" color="white">
                          Independientes
                      </MDTypography>
                    }
                  </Grid>
                  {
                    userLogin && parseInt(userLogin.admin, 10) === 1 ?
                      <BoxLinksRegister titleTop="Liga de registro" type="independiente" colorButton="warning" />
                    : null
                  }
                  <Grid item md={6} textAlign="right"  className="headerButtomShareLink"  style={{ paddingLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <MDButton variant="gradient" color="light"  style={{ height: 10 }}
                      onClick={()=>crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar Participante
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                  :
                  <MDBox>
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                    >
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationComponentOptions={{
                          rowsPerPageText: 'Participantes  por página:', 
                          rangeSeparatorText: 'de', 
                          noRowsPerPage: false, 
                          selectAllRowsItem: true, 
                          selectAllRowsItemText: 'Todos'
                        }}
                        noDataComponent={<MDBox py={5}>No se encontraron participantes.</MDBox>}
                      />
                    </DataTableExtensions>
                  </MDBox>
                  }
                </MDBox>
              </MDBox>
              <MDBox mx={2} mt={1} py={3} px={2}
              >
                {
                  userLogin.token_guest && !userLogin.admin ?
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <p style={{ fontSize: 12, fontWeight: 'bold' }}>Puede compartir el siguiente enlace con sus participantes:</p>
                      <p style={{ fontSize: 15 }} display="inline" >
                        {
                          `${domain}/cursante-invitado-empresa?empresa=true&tk=${userLogin.token_guest}`
                        }
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <div>
                        {
                          qr ?
                            <img src={qr} alt="qr" width={100}  />
                          : null
                        }
                    </div>
                    </Grid>
                    <Grid item xs={3} textAlign="right" >
                      <MDButton variant="gradient" color="light" 
                        onClick={()=>copyLink()}>
                        <Icon fontSize="small">content_copy</Icon>
                        &nbsp;Copiar enlace
                      </MDButton>
                    </Grid>
                  </Grid>
                  : null
                }
              </MDBox>
              <Message status={statusCopy} />
            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show}  timeout={50}>
           { !show ? 
            <Form data={selectedData} editar={editar} setShow={setShow} 
             getLista={getData} cursos={cursos} ocupaciones={ocupaciones} clientes={clientes} puestos={puestos} /> : ""
           }
          </Collapse>
        </Fade>
      </MDBox>
        <Modal
          isOpen={modalIsOpen}
          contentLabel="¿Seguro que desea eliminar el participante?"
          style={customStyles}
        >
          
          <Grid container spacing={2} p={4}>
            <h2>¿Seguro que desea eliminar el participante?</h2>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="info" disabled={loading}
                onClick={()=>closeModal()} fullWidth>
                Cancelar
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="warning" disabled={loading}
                onClick={()=>deleteCurso()} fullWidth>
                Eliminar
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Curso;