/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
// import faker from 'faker';

import { Link, useParams } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);


function DefaultInfoCard({ ossData }) {

	const optionsFinal = {
	  indexAxis: 'y',
	  plugins: {
	    title: {
	      display: true,
	      text: ossData.id_oss,
	    },
	  },
	  responsive: true,
	  scales: {
	    x: {
	      stacked: true,
	    },
	    y: {
	      stacked: true,
	    },
	  },
	};


  return (
    <Card>
      <MDBox p={2} justifyContent="center">
      	<Grid container spacing={4}>
      		<Grid item xs={12}>
						<Bar options={optionsFinal} data={ossData} />
      		</Grid>
      	</Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  // value: "",
  ossData: []
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  ossData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
};

// color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),

export default DefaultInfoCard;
