/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon";

import { Link } from "react-router-dom";
import QRCode from 'qrcode';
import Modal from 'react-modal';
import domtoimage from 'dom-to-image';

import { Document, PDFDownloadLink, pdf, PDFViewer  } from '@react-pdf/renderer';
import EditCertificado from "./EditCertificado";

import backImg from "../../tools/credencial_fondo.jpg";
import frame from "../../tools/qr.png";
import NoImg from "../../tools/no-img.png"; 


import CertGeneratorPDF from "../../tools/CertGeneratorPDF";
import NewCertGenerator from "../../tools/NewCertGenerator";
import CredGeneratorPDF from "../../tools/CredGeneratorPDF";
import {Get} from "../../tools/Helper";
import Message from "../../tools/Message";


function Certificados() {

  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [formData, setFormData] = useState([]);

  const handleChange = (e) =>{
    setFormData({...formData, [e.target.name]:e.target.value});
  }

  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [editar, setEditar] = useState(true);

  const [instructores, setInstructores] = useState([]);
  const [administradores, setAdministradores] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [userLogged, setUserLogged] = useState({});

  const [modalIsOpen, setIsOpen] = useState(false);
  const [credModal, setCredModal] = useState(false);

  const [dataFinal, setDataFinal] = useState(null);
  const [qr, setQr] = useState(null);

  const [blob, setBlob] = useState(null);

  const editarButton = (row) =>{
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const verButton = (row) =>{
    setSelectedData(row);
    setShow(false);
    setEditar(false);
  }

  const openModalCred = () => {
    setCredModal(true);
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
    setCredModal(false);
  }

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const downloadPDF = async(id, type) => { // prevent page reload
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get(`certificados/${id}`);

    if(request.status === "success"){
      const row = request.data;
      const qrData = await QRCode.toDataURL(`https://certificados.paymun.mx/${row.folio_unico_registro}/${row.token_certificado}-${row.id_cursante}`);

      setDataFinal(row);
      setQr(qrData);

      openModal();

      /*
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}_${row.folio_unico_registro}.pdf`;
      link.click();
      */

      setLoading(false);
    }else{
      setStatus({status:request.status, message:"Error."});
      setLoading(false);
    }
  }

  const downloadF = async() =>{

    const tmp = await pdf(
      <Document>
        <CertGeneratorPDF data={dataFinal} qr={qr} />
        <NewCertGenerator data={dataFinal} qr={qr} />
      </Document>
      ).toBlob();
      setBlob(tmp);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(tmp);
    link.download = `certificado_${dataFinal.nombre_cursante}_${dataFinal.folio_unico_registro}.pdf`;
    link.click();
  }

  const downloadJPG = async(id) => { // prevent page reload
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get(`certificados/${id}`);

    if(request.status === "success"){
      const row = request.data;
      const qrData = await QRCode.toDataURL(`https://certificados.paymun.mx/${row.folio_unico_registro}/${row.token_certificado}`);

      setDataFinal(row);
      setQr(qrData);

      openModalCred();

      /*
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}_${row.folio_unico_registro}.pdf`;
      link.click();
      */

      setLoading(false);
    }else{
      setStatus({status:request.status, message:"Error."});
      setLoading(false);
    }
  }

  const downloadFinal = async() =>{
    closeModal();
    setLoading(true);

    const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${dataFinal.folio_unico_registro}.pdf`;
      link.click();

    setLoading(false);
  }

  const buttonEdit = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;
  const buttonPdf = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={()=>downloadPDF(row.id_certificado, 0)}><Icon>picture_as_pdf</Icon></MDButton>;

  const buttonPdf3 = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={()=>downloadJPG(row.id_certificado)}><Icon>picture_as_pdf</Icon></MDButton>;

  const columns = [
      {
          name: 'Folio',
          selector: row => row.folio_unico_registro,
          compact: true,
          grow:1
      },
      {
          name: userLogged.admin=== 1 ? 'Participante' : null,
          selector: row => userLogged.admin=== 1 ? row.nombre_cursante : null,
          wrap: true,
          grow:  userLogged.admin=== 1 ? 2 : 1,
      },
      // {
      //     name: 'CURP',
      //     selector: row => row.curp_cursante,
      //     wrap: true,
      //     grow:1
      // },
      {
          name: 'Curso',
          selector: row => row.nombre_curso,
          wrap: true,
          grow:2
      },
      {
          name: 'Lugar',
          selector: row => row.lugar,
          grow:1
      },
      {
          name: 'Fecha de imparticion',
          selector: row => row.fecha_imparticion_curso,
          grow:1
      },
      {
          name: userLogged.admin=== 1 ? 'Editar' : '',
          selector: userLogged.admin=== 1 ? row => buttonEdit(row) : null,
          compact: true,
          hidden: false
      },
      {
          name: 'PDF.',
          selector: row => buttonPdf(row),
          compact: true,
      },
      {
          name: 'Cred.',
          selector: row => row.cred === "1" ? buttonPdf3(row) : "",
          compact: true,
      },
  ];

  const [catalog, setCatalog] = useState([]);
  const [data, setData] = useState([]);

  const tableData = {
    columns,
    data,
  };

  const downloadCred = () => {
    domtoimage
      .toJpeg(document.getElementById("canv"), { quality: 0.95 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `credencial_${dataFinal.folio_unico_registro}_${dataFinal.nombre_cursante}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
  }

  const getData = async e =>{
    const {user} = JSON.parse(localStorage.getItem('user'));
    setUserLogged(user);

    setStatus({status: null, message: ""});
    setLoading(true);

    let request = await Get('certificados');

    if(user.tipo === 'cursante'){
        request = await Get(`certificados-cursante-externo/${user.id_cursante}`);
    }

    if(user.tipo === 'cliente'){
      request = await Get(`certificados-cliente-externo/${user.id_cliente}`);
    }

    if(request.status === "success"){
      setData(request.data.certificados);
      setStatus({status:request.status, message:"Se ha creado exitosamente."});
    }else{
      setStatus({status:request.status, message:"Error."});
    }

    const request2 = await Get('catalogos-cursos');

    if(request2.status === "success"){
      setCatalog(request2);
    }

    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3} display="block">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Fade in={show} timeout={800}>
            <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Lista de Certificados
                    </MDTypography>
                  </Grid>
                  {
                    userLogged.admin=== 1 ?
                    <Grid item xs={6} textAlign="right" >
                      <Link to="/certificados/captura">
                        <MDButton variant="gradient" color="light">
                          Agregar
                        </MDButton>
                      </Link>
                    </Grid>
                    : null
                  }
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox> :
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                    >
                      <DataTable
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'OSS por página:', 
                            rangeSeparatorText: 'de', 
                            noRowsPerPage: false, 
                            selectAllRowsItem: true, 
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                      />
                    </DataTableExtensions>
                  }
                </MDBox>
              </MDBox>
            </Card>
            </Collapse>
            </Fade>
           {
              editar ? 
              <Fade in={!show} timeout={800}>
                <Collapse in={!show}  timeout={50}>
                  {!show ? <EditCertificado data={selectedData} setShow={setShow} getLista={getData} show={show} catalog={catalog} /> : ""}
                </Collapse>
              </Fade> : 
              ""
            }
          </Grid>
        </Grid>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
        <Grid container spacing={6} pb={2}>
          <Grid item xs={6}>
            <MDButton variant="gradient" color="success" fullWidth onClick={()=>downloadF()}>
              Descargar
            </MDButton>
          </Grid>
          <Grid item xs={6} textAlign="right" >
              <MDButton variant="gradient" color="error" fullWidth onClick={()=>closeModal()}>
                Cerrar
              </MDButton>
          </Grid>
        </Grid>
          { dataFinal && qr ? 
            <PDFViewer width="100%" height="96%" scale="3.0">
              <Document>
                <CertGeneratorPDF data={dataFinal} qr={qr} />
                <NewCertGenerator data={dataFinal} qr={qr} />
              </Document>
            </PDFViewer> : "Cargando..."
          }
        </Modal>
        <Modal
          isOpen={credModal}
          onRequestClose={closeModal}
        >
         <Grid container spacing={6} mb={2}>
          <Grid item xs={6}>
            <MDButton variant="gradient" color="success" fullWidth onClick={()=>downloadCred()}>
              Descargar
            </MDButton>
          </Grid>
          <Grid item xs={6} textAlign="right" >
              <MDButton variant="gradient" color="error" fullWidth onClick={()=>closeModal()}>
                Cerrar
              </MDButton>
          </Grid>
        </Grid>
        <div className="cont">
          { dataFinal && qr ? <div className="pos-rel border1" id="canv">
              <img
                alt="bg"
                src={backImg}
                className="pos-rel"
              />
              <img
                alt="foto"
                src={dataFinal.cursante.evidencias && dataFinal.cursante.evidencias.foto ? dataFinal.cursante.evidencias.foto : NoImg}
                className="avatar"
              />
              <img src={qr} alt="qr" className="qr" />
              <div className="nombre-acreditado">
                Nombre del acreditado
                <br />
                <span className="bold">{ dataFinal.nombre_cursante }</span>
              </div>
              <div className="curso">
                Curso:
                <br />
                <span className="bold">{ dataFinal.curso.nombre }</span>
              </div>
              <div className="fecha-finalizacion">
                Fecha de finalización:
                <br />
                <span className="bold">{ dataFinal.fecha_termino }</span>
              </div>
              <div className="no-certificacion">
                No. de certificación:
                <br />
                <span className="bold">{ dataFinal.folio_unico_registro }</span>
              </div>
              <div className="desarrollo">Desarrollo de Talento</div>
              <div className="humano">Humano</div>
            </div> : "Cargando..."}
        </div>
      </Modal>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Certificados;
