/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useMemo } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import 'react-data-table-component-extensions/dist/index.css';


import { parseCurrency } from "tools/utils";
import {Get, Post} from "../../tools/Helper";
import Message from "../../tools/Message";
import EstatusFactura from "./graficas/EstatusFactura";
import CotizacionesEmitidasAceptadas from "./graficas/CotizacionesEmitidasAceptadas";
import ComplexStatisticsCard from "./StatisticsCards/ComplexStatisticsCard";


function DashboardCotizaciones() {

  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const [ingresosTotales, setIngresosTotales] = useState(0);
  const [valorCotizaciones, setValorCotizaciones] = useState(0);
  const [conversionCotizacion, setConversionCotizacion] = useState(0);
  const [facturacionAnual, setFacturacionAnual] = useState(0);
  const [valorCartera, setValorCartera] = useState(0);
  const [cotizacionesEmitidas, setCotizacionesEmitidas] = useState([]);
  const [estatusFactura, setEstatusFactura] = useState([]);


  const getIngresosTotales = async () =>{
    setStatus({status: null, message: ""});
    setLoading(true);
    const request = await Post(`ingresos-totales`);
    if(request.status === "success"){
      setIngresosTotales(request.data.ingresosTotales);
      setValorCotizaciones(request.data.valorCotizaciones);
      setConversionCotizacion(request.data.conversionCotizacion);
      setFacturacionAnual(request.data.facturacionAnual);
      setValorCartera(request.data.valorCartera);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    setLoading(false);
  }

  const getCotizacionesEmitidas = async () =>{
    setStatus({status: null, message: ""});
    const request = await Post(`cotizaciones-emitidas-aceptadas`);
    if(request.status === "success"){
      setCotizacionesEmitidas(request.data);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
  }

  const getEstatusFactura = async () =>{
    setStatus({status: null, message: ""});
    const request = await Post(`estatus-factura`);
    if(request.status === "success"){
      setEstatusFactura(request.data);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
  }

  useEffect(()=>{
    getIngresosTotales();
    getCotizacionesEmitidas();
    getEstatusFactura();
  },[]);

  const cargando = () => <Grid item xs={12} lg={12}>
    <Card>
      <MDBox item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
        <CircularProgress size="6.66rem" /> 
        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
      </MDBox>
    </Card>
  </Grid>

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3} display="block">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Fade in={show} timeout={800}>
            <Collapse in={show} timeout={50}>
            <MDBox
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Tablero de Control
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <Grid container spacing={2} py={4}>
                {loading ? cargando() : 
                <>
                <Grid container spacing={2} md={12} ml={1} mt={2}>
                    <Grid item xs={12} md={4}>
                        <MDBox mb={1.5}>
                          <ComplexStatisticsCard
                            color="dark"
                            icon="monetization_on"
                            title="Ingresos totales"
                            count={parseCurrency((parseFloat(ingresosTotales)).toFixed(2))}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MDBox mb={1.5}>
                          <ComplexStatisticsCard
                            color="info"
                            icon="paid"
                            title="Valor de Cotizaciones"
                            count={parseCurrency((parseFloat(valorCotizaciones)).toFixed(2))}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MDBox mb={1.5}>
                          <ComplexStatisticsCard
                            color="primary"
                            icon="currency_exchange"
                            title="Conversión de Cotizaciones"
                            count={`%${(parseFloat(conversionCotizacion)).toFixed(2)}`}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MDBox mb={1.5}>
                          <ComplexStatisticsCard
                            color="primary"
                            icon="savings"
                            title="Valor de Cartera"
                            count={parseCurrency((parseFloat(valorCartera)).toFixed(2))}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MDBox mb={1.5}>
                          <ComplexStatisticsCard
                            color="success"
                            icon="request_quote"
                            title="Facturación Anual"
                            count={parseCurrency((parseFloat(facturacionAnual)).toFixed(2))}
                          />
                        </MDBox>
                      </Grid>
                    </Grid>
                  <Grid item xs={12} lg={6}>

                    <MDBox pb={2}>
                      <Card>
                        <MDBox p={3}>
                          <Grid container spacing={2}>
                            <Grid item md={12}>  
                              <CotizacionesEmitidasAceptadas dataChart={cotizacionesEmitidas} />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                    <Grid item xs={12} lg={6}>
                    <MDBox pb={2}>
                      <Card>
                        <MDBox p={3}>
                          <Grid container spacing={2}>
                            <Grid item md={12}>  
                              <EstatusFactura dataChart={estatusFactura} />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  </>
                }
              </Grid>
            </Collapse>
            </Fade>
           
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default DashboardCotizaciones;
