import React, {useMemo} from 'react';
import { Outlet , Navigate } from 'react-router-dom';
import PropTypes from "prop-types";
 
function Logout(){
 
localStorage.clear();
return <Navigate to="/" />
       
};

export default Logout;