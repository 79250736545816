/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import {Post,Put} from "../../tools/Helper";
import Message from "../../tools/Message";

function ResponsableForm({editar, data, setShow, getLista}) {

	const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

	useEffect(()=>{
    setFormData(data);
    setStatus({status: null, message: ""});
  },[data,setFormData]);

  const handleChange = (e) =>{
      setFormData({...formData, [e.target.name]:e.target.value});
  }

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const tempData = formData;

    const request = editar ? await Put(`responsables/${formData.id_responsable}`,formData) : await Post('responsables',formData) ;

    if(request.status === "success"){
      await getLista();
      if(editar){
        setFormData(tempData);
      }
    }


    setStatus(request);
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    {editar ? "Actualizar" : "Agregar"} Responsable
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  <MDButton variant="gradient" color="light" 
                    onClick={()=>setShow(true)}>
                    <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar a la lista
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : <><Grid item xs={4} mt="auto">
                        <MDInput type="text" label="Nombre" name="nombre"
                            error={verifyError("nombre")} errorMsg={getError("nombre")}
                            value={formData.nombre} onChange={event => handleChange(event)} fullWidth />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="text" label="Puesto" name="puesto"
                            error={verifyError("puesto")} errorMsg={getError("puesto")}
                            value={formData.puesto} onChange={event => handleChange(event)} fullWidth />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDInput type="text" label="Correo" name="correo"
                            error={verifyError("correo")} errorMsg={getError("correo")}
                            value={formData.correo} onChange={event => handleChange(event)} fullWidth />
                      </Grid>
                      <Grid item xs={6}>
                        <MDButton variant="gradient" color="info" disabled={loading}
                            onClick={()=>doRequest()} fullWidth>
                            {editar ? "Actualizar" : "Agregar"}
                          </MDButton>
                      </Grid></>}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
	);
}

ResponsableForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default ResponsableForm;