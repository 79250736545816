import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

function CotizacionesEmitidasAceptadas({dataChart}){
    const data = {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [
          {
            label: 'Emitidas',
            backgroundColor: 'rgba(255,206,86,0.4)',
            borderColor: 'rgba(255,206,86,1)',
            borderWidth: 1,
            data: dataChart.emitidas,
          },
          {
            label: 'Aceptadas',
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
            data:  dataChart.aceptadas,
          },
        ],
      };
    
      const options = {
        scales: {
          x: { stacked: false },
          y: { stacked: false },
        },
      };
    
      return (
        <div className='card-dashboard'>
          <p>Cotizaciones Emitidas vs. Aceptadas</p>
          <Bar data={data} options={options} />
        </div>
      );
    };
    
    CotizacionesEmitidasAceptadas.propTypes = {
      dataChart: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    };

export default CotizacionesEmitidasAceptadas;
