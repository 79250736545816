/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import Select from 'react-select';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import {Post,Put, Del} from "../../tools/Helper";
import Message from "../../tools/Message";

function FirmanteForm({editar, data, setShow, getLista}) {

	const [formData, setFormData] = useState(data);
	const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [filePickerId, setFilePickerId] = useState("");

  const inputRef = useRef(null);

  const tipos = [
    {
      'tipo': 'inst',
      'nombre_tipo': 'Instructor'
    },
    {
      'tipo': 'adm',
      'nombre_tipo': 'Administrador'
    }
  ];

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const modifySignature = (signature) =>{
    setFormData({...formData, firma:signature});
    if(signature === null){
      const randomString = Math.random().toString(10);
      setFilePickerId(randomString);
    }
  }

  const handleClick = () => {
    // open file input box on click of other element
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(fileObj);
    reader.onload = function () {
        modifySignature(reader.result);
    };
  }

	useEffect(()=>{
	    setFormData(data);
	    setStatus({status: null, message: ""});
  	},[data,setFormData]);

  const handleChange = (e, name = "", value = "") =>{
    if(name !== ""){
      setFormData({...formData, tipo:e[name], tipoArr: e});
      console.log(formData);
    }else{
      setFormData({...formData, [e.target.name]:e.target.value});
    }
  }

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);
    const tempData = formData;
    const request = editar ? await Put(`firmantes/${formData.id_firmante}`,formData) : await Post('firmantes',formData) ;

    if(request.status === "success"){
      await getLista();
      if(editar){
        setFormData(tempData);
      }
    }

    setStatus(request);
    setLoading(false);
  }

  const deletePregunta = async e =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`firmantes/${formData.id_firmante}`);

    if(request.status === "success"){
      await getLista();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const getTipo = tipos.find(option => option.tipo === formData.tipo);

	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    {editar ? "Actualizar" : "Agregar"} Firmante
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  <MDButton variant="gradient" color="light" 
                    onClick={()=>setShow(true)}>
                    <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar a la lista
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : <>
                    <Grid item xs={12} lg={4} mt="auto">
                      <MDInput type="text" label="Nombre Completo" name="nombre_completo"
                          error={verifyError("nombre_completo")} errorMsg={getError("nombre_completo")}
                          value={formData.nombre_completo} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} lg={4} mt="auto">
                      <MDInput type="text" label="Puesto" name="puesto"
                          error={verifyError("puesto")} errorMsg={getError("puesto")}
                          value={formData.puesto} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} lg={4} mt="auto">
                      <MDInput type="text" label="Teléfono" name="telefono"
                          error={verifyError("telefono")} errorMsg={getError("telefono")}
                          value={formData.telefono} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} lg={3} mt="auto">
                      <MDInput type="text" label="Correo" name="correo"
                          error={verifyError("correo")} errorMsg={getError("correo")}
                          value={formData.correo} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} lg={3} mt="auto">
                      <Select className={`z-10 ${verifyError("tipo") ? "border-red" : ""}`}
                        options={tipos} placeholder="Tipo"
                        value={getTipo} onChange={(e) => handleChange(e,"tipo")}
                        getOptionValue={(option) => `${option.tipo}`}
                        getOptionLabel={(option) => `${option.nombre_tipo}`}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3} mt="auto">
                      <MDInput type="text" label="Registro 1" name="registro_1"
                          error={verifyError("registro_1")} errorMsg={getError("registro_1")}
                          value={formData.registro_1 ?? ''} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} lg={3} mt="auto">
                      <MDInput type="text" label="Registro 2" name="registro_2"
                          error={verifyError("registro_2")} errorMsg={getError("registro_2")}
                          value={formData.registro_2 ?? ''} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} lg={4} mt="auto">
                    {
                      formData.firma ?
                      <MDButton variant="gradient" color="error"
                        display="inline"
                        onClick={()=>modifySignature(null)} fullWidth>
                        Eliminar Firma
                      </MDButton>
                      :
                      <MDButton variant="gradient" color="info"
                        display="inline"
                        onClick={()=>handleClick()} fullWidth>
                        Agregar firma
                      </MDButton>
                    }
                    </Grid>
                    <Grid item xs={12} lg={4} mt="auto">
                      <MDTypography variant="h6" display="inline" pr={1}>
                        Firma:
                      </MDTypography>
                      <input
                        type="file"
                        name="myImage"
                        style={{display:'none'}}
                        key={filePickerId || ''}
                        ref={inputRef}
                        onChange={handleFileChange}
                      />
                      {formData.firma ?
                          <fragment>
                            <img alt="not fount" src={formData.firma} className="w-100" width="100%" />
                
                          </fragment>
                        : <fragment>
                            <MDTypography variant="h6" display="inline" fontWeight="light" pl={1}>
                            No ha seleccionado una imagen.
                            </MDTypography>
                          </fragment>
                        }
                      <MDTypography variant="caption" display="inline" pr={1}>
                        Tamaño recomendado: 210px (alto) x 440px (ancho)
                       </MDTypography>
                    </Grid>
                    <Grid item xs={12} lg={8} mt="auto" />
                    <Grid item xs={6} mt="auto" ml="auto">
                      <MDButton variant="gradient" color="info" disabled={loading}
                          onClick={()=>doRequest()} fullWidth>
                          {editar ? "Actualizar" : "Agregar"} Firmante
                        </MDButton>
                    </Grid>
                    {editar ? <Grid item xs={6}>
                      <MDButton variant="gradient" color="warning" disabled={loading}
                          onClick={()=>openModal()} fullWidth>
                          Eliminar
                        </MDButton>
                      </Grid> : ""}
                    </>}
              </Grid>
            </MDBox>
          </Card>
            <Modal
            isOpen={modalIsOpen}
            contentLabel="¿Seguro que desea eliminar el registro?"
            style={customStyles}
          >
            
            <Grid container spacing={2} p={4}>
              <h2>¿Seguro que desea eliminar el registro?</h2>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="info" disabled={loading}
                  onClick={()=>closeModal()} fullWidth>
                  Cancelar
                </MDButton>
              </Grid>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="warning" disabled={loading}
                  onClick={()=>deletePregunta()} fullWidth>
                  Eliminar
                </MDButton>
              </Grid>
            </Grid>
          </Modal>
        </MDBox>
	);
}

FirmanteForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default FirmanteForm;