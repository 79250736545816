/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import ReactDOM, { render } from 'react-dom';

import Icon from "@mui/material/Icon";

import { Link } from "react-router-dom";

import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import OssForm from "./OssForm";
import VerOss from "./VerOss";

import PDFGenerator from "../../tools/PDFGenerator";
import { Get } from "../../tools/Helper";
import Message from "../../tools/Message";


function ListaOss() {

  const [data, setData] = useState([]);
  const [dataSearch, setdataSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });

  const downloadButton = useRef(null);

  const [totalRows, setTotalRows] = useState(10);
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [activeSearch, setActiveSearch] = useState(false);
  const btnCancel = useRef(null);

  const getData = async (page, rowsPerPage1) => {

    setLoading(true);
    setStatus({ status: null, message: "" });

    const request = await Get(`getListPaginateOSS?page=${page}&per_page=${rowsPerPage1}&delay=1`);

    if (request.status === "success") {
      setData(request.data.data);
      setTotalRows(request.data.total);
      setStatus({ status: request.status, message: "Se ha creado exitosamente." });
    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  };

  const getDataV1 = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    // const request = await Get('getAll_OSS');
    const request = await Get('oss');

    if (request.status === "success") {
      setData(request.data);
      setStatus({ status: request.status, message: "Se ha creado exitosamente." });
    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  }

  useEffect(() => {
    getData(1, 10);
  }, []);

  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [formData, setFormData] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [editar, setEditar] = useState(true);

  const editarButton = (row) => {
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const searchOSs = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);
    setdataSearch([]);

    const request = await Get(`searchOss/${search}`);

    if (request.status === "success") {
      setdataSearch(request.data);
      setActiveSearch(true);
      setStatus({ status: request.status, message: "Se ha realizado la búsqueda exitosamente." });
      btnCancel.current?.focus();

    }
    else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  }


  const buscarButton = () => {
    searchOSs();
  }

  const clearSearch = () => {

    setActiveSearch(false);
    getData(1, 10);
    setSearch('');

  }

  const verButton = (row) => {
    setSelectedData(row);
    setShow(false);
    setEditar(false);
  }

  const download = (filename, url) => {
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const createAndDownloadPDF = (pdfContent, filename, callback) => {
    setTimeout(() => {
      const link = (
        <div id={filename}>
          <PDFDownloadLink document={pdfContent} fileName={filename}>
            {({ blob, url, loading2, error }) => {
              if (!loading2) {
                download(filename, URL.createObjectURL(blob));
                callback();
              }
            }}
          </PDFDownloadLink>
        </div>
      );

      const elem = document.createElement('div');
      document.getElementById('pdfButton').appendChild(elem);
      render(link, elem);
    }, 50);
  };

  const buildPDF2 = (row) => {
    if (!loading) {
      setLoading(true);
      console.log(row);
      createAndDownloadPDF(<PDFGenerator data={row} />, `oss_${row.id_oss}`, () =>
        setLoading(false)
      );
    }
  };

  const buildPDF = (row) => {
    setSelectedData(row);
    downloadButton.current.focus();
  };

  const downloadPDF = async (row) => { // prevent page reload
    const blob = await pdf(
      <PDFGenerator data={row} />
    ).toBlob();

    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  const buttonView = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={() => verButton(row)}><Icon>previewOutlinedIcon</Icon></MDButton>;
  const buttonEdit = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton>;
  const buttonPdf = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={() => downloadPDF(row)}><Icon>picture_as_pdf</Icon></MDButton>;
  const statusOss = (row) => <Icon color={row.elabora === '' || row.elabora === null ? 'warning' : 'success'} fontSize="20">{row.elabora === '' || row.elabora === null ? 'remove_circle_outline' : 'check_circle'}</Icon>;

  const columns = [
    {
      name: 'N°',
      selector: row => row.id_oss,
      width: "80px",
    },
    {
      name: 'N° Cotización',
      selector: row => row.num_cotizacion,
      width: "110px",
    },
    {
      name: 'Fecha',
      selector: row => row.fecha_oss,
      width: "110px",
    },
    {
      name: 'Cliente',
      selector: row => row.cliente_nombre,
      wrap: true
    },
    {
      name: 'Municipio',
      selector: row => row.municipio,
    },
    {
      name: 'Responsable',
      selector: row => row.responsables.map((resp) => `${resp.nombre}`),
      wrap: true
    },
    {
      name: '',
      selector: row => buttonEdit(row),
      compact: true,
      width: "60px",
    },
    {
      name: '',
      selector: row => buttonPdf(row),
      compact: true,
      width: "60px",

    },
    {
      name: 'OSS Capturada',
      selector: row => statusOss(row),
      width: "120px",
      center: true,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const handlePageChange = (page) => {
    getData(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    // if (!data.length) return; // when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleKeyPress = (e) => {

    console.log(e.key);

    if (e.key === 'Enter') {
      searchOSs();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3} display="block">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Fade in={show} timeout={800}>
              <Collapse in={show} timeout={50}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <Grid container spacing={6}>
                      <Grid item xs={6}>
                        <MDTypography variant="h4" display="inline" mr="auto" color="white">
                          Lista de OSS
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6} textAlign="right" >
                        <Link to="/captura-oss">
                          <MDButton variant="gradient" color="light">
                            Agregar
                          </MDButton>
                        </Link>
                      </Grid>
                    </Grid>
                  </MDBox>


                  <MDBox pt={3} px={3}>
                    <Grid container>

                      <Grid item xs={2}>

                        <MDInput type="text" label="Buscar" name="buscar_oss"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          onKeyPress={handleKeyPress}
                          variant="standard"
                          disabled={activeSearch}
                          fullWidth
                        />
                      </Grid>


                      {!activeSearch ?
                        <Grid item xs={1} px={1} pt={.3}>
                          <MDButton variant="gradient" color="info" iconOnly size="medium" onClick={() => buscarButton()}>
                            <Icon>search</Icon>
                          </MDButton>
                        </Grid>
                        :
                        <Grid item xs={1} px={1} pt={.3}>
                          <MDButton variant="gradient" color="warning" iconOnly size="medium" onClick={() => clearSearch()} ref={btnCancel}>
                            <Icon>cancel</Icon>
                          </MDButton>
                        </Grid>
                      }
                    </Grid>
                  </MDBox >


                  {!activeSearch ?

                    // Tabla de lista de OSS General
                    <MDBox pt={3}>
                      <MDBox px={3}>
                        {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                          <CircularProgress size="10rem" />
                          <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                        </MDBox> :

                          <DataTable                            
                            pagination
                            paginationServer
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            paginationPerPage={rowsPerPage}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            paginationComponentOptions={{
                              rowsPerPageText: 'OSS por página:',
                              rangeSeparatorText: 'de',
                              noRowsPerPage: false,
                              selectAllRowsItem: false,
                            }}
                          />

                        }
                      </MDBox>
                    </MDBox>
                    :
                    // Tabla de resultado de busqueda
                    <MDBox pt={3}>
                      <MDBox px={3}>
                        {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                          <CircularProgress size="10rem" />
                          <MDTypography variant="h4" pt={3}>Buscando...</MDTypography>
                        </MDBox> :

                          <DataTable
                            pagination                            
                            columns={columns}
                            data={dataSearch}
                            progressPending={loading}
                            paginationComponentOptions={{
                              rowsPerPageText: 'OSS por página:',
                              rangeSeparatorText: 'de',
                              noRowsPerPage: false,
                              selectAllRowsItem: false,
                            }}
                          />

                        }
                      </MDBox>
                    </MDBox>
                  }

                </Card>
              </Collapse>
            </Fade>
            {
              editar ?
                <Fade in={!show} timeout={800}>
                  <Collapse in={!show} timeout={50}>
                    {!show ? <OssForm data={selectedData} setShow={setShow} getLista={getData} show={show} /> : ""}
                  </Collapse>
                </Fade> :
                <Fade in={!show} timeout={800}>
                  <Collapse in={!show} timeout={50}>
                    <VerOss data={selectedData} setShow={setShow} />
                  </Collapse>
                </Fade>
            }
          </Grid>
        </Grid>
      </MDBox>
      {// selectedData.length !== 0 ? <PDFDownloadLink document={<PDFGenerator data={selectedData} />} fileName={`oss_${selectedData.id_oss}`} ref={downloadButton}/> : "bbbb"
      }
      <Footer />
    </DashboardLayout>
  );
}

export default ListaOss;
