/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

import { Link, useParams } from "react-router-dom";
import {Get} from "../../../../tools/Helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function DefaultInfoCard({ actionBtn, data }) {

	const [ossGraph, setOssGraph] = useState([]);
	const [loading, setLoading] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [firstTime, setFirstTime] = useState(true);

	const listaServicios = data.servicios.map((serv)=><>
			<Grid item xs={6} lg={10} my="auto">
      			<MDTypography display="flex" variant="caption" color="text" fontWeight="regular">
					{serv.descripcion}
				</MDTypography>
      		</Grid>
      		<Grid item xs={6} lg={2} my="auto">
					<Link to={`/acciones-oss/${data.id_oss}/servicio/${serv.servicio.id_servicio}`}>      			
      			<MDButton variant="gradient" mx="auto"
    				color="info" iconOnly><Icon>check_box</Icon>
    				</MDButton>
    			</Link>
      </Grid>
		</>
	);

	const optionsFinal = {
	  indexAxis: 'y',
	  plugins: {
	    title: {
	      display: true,
	      text: ossGraph.id_oss,
	    },
	  },
	  responsive: true,
	  aspectRatio: "3.4",
	  scales: {
	    x: {
	      stacked: true,
	      ticks: {
	        min: 0,
	        stepSize: 1,
	        max: 25
	      }
	    },
	    y: {
	      stacked: true,
	    },
	  },
	};

	const getOssGraph = async e =>{
		setLoading(true);

    const request = await Get(`grafica-oss/${data.id_oss}`);

    if(request.status === "success"){
      setOssGraph(request);
    }
    setLoading(false);
	}

	useEffect(()=>{
		if(expanded && firstTime){
			getOssGraph();
			setFirstTime(false);
		}
	},[expanded]);

	 const cargando = () => <Grid item xs={12} lg={12}>
    <Card>
      <MDBox item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
        <CircularProgress size="6.66rem" /> 
        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
      </MDBox>
    </Card>
  </Grid>

  return (
	    <Grid item md={6} xs={12} className=" anim" mx="auto" onClick={()=>setExpanded(!expanded)}>
		    <Card>
		      <MDBox p={2} justifyContent="center">
		      	<Grid container spacing={4}>
		        	<Grid item xs={6} lg={3} textAlign="center" verticalAlign="middle">
		        		<MDBox
				          display="grid"
				          justifyContent="center"
				          alignItems="center"
				          bgColor="info"
				          color="white"
				          width="4rem"
				          height="4rem"
				          shadow="md"
				          borderRadius="lg"
				          variant="gradient"
				          mx="auto"
				        >
				          <Icon fontSize="default">description</Icon>
				        </MDBox>
				        <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize" pt={2}>
							{data.id_oss}
				        </MDTypography>
		      		</Grid>
		      		<Grid item xs={6} lg={9}>
		        		<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
									{data.cliente_nombre} <MDBadge badgeContent="nuevo" color="error" variant="gradient" size="sm" />
				        </MDTypography>
				        <Grid container>
					        <Grid item xs={5}>
					        	<MDTypography variant="caption" color="text" fontWeight="regular"  class="line-h">
											{data.fecha_oss}
										</MDTypography>
					        </Grid>
					        <Grid item xs={5}>
					        	<MDTypography variant="caption" color="text" fontWeight="regular" class="line-h">
											{data.municipio}{data.estado ? `, ${data.estado}` : ""}
										</MDTypography>
					        </Grid>
					        	<Grid item xs={2}>
  					        	<MDBox
  						          display="grid"
  						          justifyContent="center"
  						          alignItems="center"
  						          bgColor={parseInt(data.archivado,10) === 0 ? "error" : "success"}
  						          color="white"
  						          width="2.9rem"
  						          height="2.9rem"
  						          shadow="md"
  						          borderRadius="lg"
  						          variant="gradient"
  						          mx="auto"
  						          onClick={()=>actionBtn(data.id_oss)}
  						        >
  						          {parseInt(data.archivado,10) === 0 ? <Icon fontSize="default">archive</Icon> : <Icon fontSize="default">unarchive</Icon>}
  						        </MDBox>
  					        </Grid>
				        </Grid>
				        <MDTypography variant="caption" color="text" py={0} my={0} fontWeight="regular"  className="line-h">
							{data.info_adicional}
						</MDTypography>
		      		</Grid>
		      	</Grid>
		      </MDBox>
		      {expanded ? 
		      	<MDBox p={2}>
	  	      	<hr />
	  	      	{loading ? cargando() : <Bar options={optionsFinal} data={ossGraph} />}
	  		      <MDBox pb={2} px={3} lineHeight={1.25}>
	  		      	<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
	  							Lista de Servicios
	  						</MDTypography>
	  				    <Grid container spacing={4}>
	  		      		{listaServicios}
	  		      	</Grid>
	  		      </MDBox>
	  		      <MDBox pb={2} px={2} lineHeight={1.25}>
	  		      	<Grid container spacing={4}>
	  		      		<Grid item xs={6}>
	  		      			<Link to={`/detalles/${data.id_oss}`}>
	  		      				<MDButton variant="gradient" color="info" fullWidth>
	  		                	Detalles OSS
	  		              </MDButton>
	  		            </Link>
	  		      		</Grid>
	  		      		<Grid item xs={6}>
	  		      			<Link to={`/evidencias/${data.id_oss}/servicio/inicio`}>
	  		      				<MDButton variant="gradient" color="success" fullWidth>
	  		                Evidencias subidas
	  		              </MDButton>
	  		            </Link>
	  		      		</Grid>
	  		      	</Grid>
	  		      </MDBox>
	  	      </MDBox> : ""}
		    </Card>
		  </Grid>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  // value: "",
  data: [],
  // ossGraph: {id_oss:0}
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  actionBtn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  // ossGraph: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
};

// color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),

export default DefaultInfoCard;
