/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';


import Select from 'react-select';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import compress from 'compress-base64';

import {Post,Put, Del, Get, PostGuest} from "../../tools/Helper";
import Message from "../../tools/Message";

import canvasPreview from '../certificados/canvasPreview'
import 'react-image-crop/dist/ReactCrop.css';
import useDebounceEffect from '../certificados/useDebounceEffect';

import backImg from "../../tools/credencial_fondo.jpg";
import frame from "../../tools/qr.png"; 

import Cursos from './Cursos';

function ServicioForm({editar, data, setShow, isGuest, getLista, cursos, ocupaciones, clientes, statusMessage}) {


  /* CROP */
  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  } 

  const [searchParams, setSearchParams] = useSearchParams();

  const [formData, setFormData] = useState(data);

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(9 / 12)

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()

      reader.onload = event => {
        compress(event.target.result, {
          width: 600,
          type: 'image/png', // default
          quality: 0.8
        }).then(result => {
          setImgSrc(result);
          setFormData({...formData, evidencias:{...formData.evidencias, foto: result, foto_nombre: e.target.files[0].name}});
        });
      };
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0,
        )
      }
    },
    100,
    [completedCrop, scale, 0],
  );

  /* */

  const sexos = [
    {
      'value': "Masculino",
      'text': 'Masculino'
    },
    {
      'value': "Femenino",
      'text': 'Femenino'
    }
  ];

  const escolaridades = [
    {
      'value': "Primaria",
      'text': 'Primaria'
    },
    {
      'value': "Secundaria",
      'text': 'Secundaria'
    },
    {
      'value': "Bachillerato",
      'text': 'Bachillerato'
    },
    {
      'value': "Carrera Tecnica",
      'text': 'Carrera Tecnica'
    },
    {
      'value': "Licenciatura",
      'text': 'Licenciatura'
    },
  ];

	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({status: null, message: ""});
  const [dataFinal, setDataFinal] = useState(null);
  const [qr, setQr] = useState(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [messageSizeFile, setMessageSizeFile] = useState({
    status: false,
    type: ''
  });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const emptyCursosArray = [{id_curso:''}];
  const [cursosArray, setCursosArray] = useState(emptyCursosArray);

  const setCurArr = (d) =>{
    setCursosArray(d);
  }

  const getDataCert = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get(`cursantes/${formData.id_cursante}`);

    if(request.status === "success"){
        const temp = request.data;
        if(!temp.evidencias){
          temp.evidencias = {
            curp: '',
            curp_nombre: '',
            foto: '',
            foto_nombre: '',
            ine: '',
            ine_nombre: '',
          };
        }

        if(temp.cursos.length > 0){
          setCursosArray(temp.cursos);
          console.log(temp.cursos);
        }

        setFormData(temp);
    }else{
      setStatus(request);
    }

    setLoading(false);
  }

  useEffect(()=>{
    if(editar){
      getDataCert();
    }
  },[]);


  const handleChange = (e, name = "", value = "") =>{
    const userLogin = JSON.parse(localStorage.getItem("user"));
    if(name === ""){
      if(e.target.name === 'nombre_completo' || e.target.name === 'curp') {
        setFormData({
          ...formData, 
          [e.target.name]:(e.target.value).toUpperCase(),
          tipo_usuario: userLogin ? userLogin.user.tipo : null,
          id_usuario: userLogin ? userLogin.user.id : null
        });
        return;
      }
      setFormData({
        ...formData, 
        [e.target.name]:e.target.value,
        tipo_usuario: userLogin ? userLogin.user.tipo : null,
        id_usuario: userLogin ? userLogin.user.id : null
      });
    }else if(value === "id_ocupacion"){
      setFormData({...formData, [value]:e.id_ocupacion, [name]:e});
    }else if(value === "id_cliente"){
      setFormData({...formData, [value]:e.id_cliente, [name]:e});
    }else{
      setFormData({
        ...formData, 
        [value]:e.value, 
        [name]:e,
        tipo_usuario: userLogin ? userLogin.user.tipo : null,
        id_usuario: userLogin ? userLogin.user.id : null
      });
    }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const handleCheck = (e) =>{
    setFormData({...formData, acepta:e.target.checked});
  }

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);
    const tempData = formData;

    tempData.cursos = cursosArray;
    const tokenUserExternal = searchParams.get("tk")
    let request = '';
    if(tokenUserExternal){
      const newData = {
        ...formData,
        token_guest: tokenUserExternal
      }
      request = await PostGuest('cursante-p',newData);
    }else{
      request = editar ? await Put(`cursantes/${formData.id_cursante}`,formData) : await Post('cursantes',formData);
    }

    if(request.status === "success"){

      setStatus(request);
      setLoading(false);
      
      if(tokenUserExternal){
        return;
      }

      const Olduser = JSON.parse(localStorage.getItem('user'));
      console.log(Olduser.user)
      const user = {
        ...Olduser.user,
        id_cursante: request.data.id_cursante
      };
      const updateUser = {
        ...Olduser,
            user
      }
  
      localStorage.setItem('user', JSON.stringify(updateUser));

      await getLista();
      if(editar){
        setFormData(tempData);
      }
      return;
    }

    setStatus(request);
    setLoading(false);
  }

  const deleteCursante = async e =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`cursantes/${formData.id_curso}`);

    if(request.status === "success"){
      await getLista();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    Paso 1: Registro
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  {
                    !isGuest ?
                      <MDButton variant="gradient" color="light" 
                        onClick={()=>setShow(true)}>
                        <Icon fontSize="small">arrow_back</Icon>
                          &nbsp;Regresar
                      </MDButton>
                    : null
                  }
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Message status={statusMessage} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : <>
                    <Grid item xs={12} md={6} mt="auto">
                      <MDInput type="text" label="Nombre y apellidos" name="nombre_completo"
                          error={verifyError("nombre_completo")} errorMsg={getError("nombre_completo")}
                          value={formData.nombre_completo} onChange={event => handleChange(event)} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6} mt="auto">
                      <MDInput type="text" label="Correo" name="correo"
                          error={verifyError("correo")} errorMsg={getError("correo")}
                          value={formData.correo} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6} mt="auto">
                      <MDInput type="text" label="Telefono" name="telefono"
                          error={verifyError("telefono")} errorMsg={getError("telefono")}
                          value={formData.telefono} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDButton variant="gradient" color="info" disabled={loading}
                        onClick={()=>doRequest()} fullWidth>
                        {editar ? "Actualizar" : "Agregar"}
                      </MDButton>
                  </Grid>
                    </>}
              </Grid>
            </MDBox>
          </Card>
          <Modal
            isOpen={modalIsOpen}
            contentLabel="¿Seguro que desea eliminar el curso?"
            style={customStyles}
          >
            <Grid container spacing={2} p={4}>
              <h2>¿Seguro que desea eliminar el curso?</h2>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="info" disabled={loading}
                  onClick={()=>closeModal()} fullWidth>
                  Cancelar
                </MDButton>
              </Grid>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="warning" disabled={loading}
                  onClick={()=>deleteCursante()} fullWidth>
                  Eliminar
                </MDButton>
              </Grid>
            </Grid>
          </Modal>
        </MDBox>
	);
}

ServicioForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  isGuest: PropTypes.func.isRequired,
  cursos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  ocupaciones: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  clientes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  statusMessage: PropTypes.func.isRequired,
};

export default ServicioForm;